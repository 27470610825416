import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import MuiListItem from "@material-ui/core/ListItem";

import ListItemText from "@material-ui/core/ListItemText";
import {
  setAcknowledged,
  setNewCompanyDetails,
  setSearcheText,
  setSearchedOrg,
  viewOrganizationEsgGpt,
} from "../../../redux/actions/gpt/esgGptAction";
import { Typography } from "@material-ui/core";
import CustomCompaniesRange from "../../UI/CustomRange/CustomCompaniesRange";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    // border: "solid",
    // height: "50%",
    // overflow: "scroll",
  },
}));

export const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },
    "&:hover": {
      backgroundColor: "#EBF1F8",
      color: "#15314E",
      fontSize: "14px",
      fontWeight: 600,
      "& .MuiListItemIcon-root": {
        // color: "white",
      },
    },

    color: "#6C6C6C",
    fontSize: "14px",
    fontWeight: 500,
    cursor: "pointer",
  },
  selected: {},
})(MuiListItem);

export default function EsgGptCompanyList() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const login = useSelector((state) => state.login);

  const { organizations, searchedOrganizations, newOrganization ,acknowledged} = useSelector(
    (state) => state.esgGptReducer
  );

  // console.log("searchedOrganizations", searchedOrganizations);

  const [selectedID, setSelectedId] = React.useState(0);

  const handleListItemClick = (event, _id, organization) => {
    window.location.pathname !== "/esg_gpt/company_profiles" &&
      history.push("/esg_gpt/company_profiles");
    acknowledged && dispatch(setAcknowledged(false));
    setSelectedId(_id);
    dispatch(setNewCompanyDetails(organization));
    // console.log(_id, organization);
  };

  React.useEffect(() => {
    dispatch(viewOrganizationEsgGpt(login.token));
    // dispatch(setNewCompanyDetails(organizations[0]));
    return () => {
      dispatch(setSearchedOrg([]));
      dispatch(setSearcheText(""));
    };
  }, []);

  React.useEffect(() => {
    organizations?.repositories &&
      setSelectedId(organizations?.repositories[0]?._id);
      !newOrganization?._id &&  organizations?.repositories && dispatch(setNewCompanyDetails(organizations?.repositories[0]));
  }, [organizations.repositories]);

  // console.log("new", newOrganization);
  // console.log("organizations", organizations);
  // const [searchedOrg, setSearchedOrg] = useState([]);

  useEffect(() => {
    const filtered = organizations?.repositories?.filter((item) => {
      let company = item?.name?.toLowerCase().trim();
      if (company?.includes(searchedOrganizations?.text?.toLowerCase().trim()))
        return item;
    });

    filtered &&  dispatch(setSearchedOrg(filtered));

    // console.log("filtered", filtered);
  }, [searchedOrganizations?.text]);

  const handleRedirect = () => {
    history.push("/esg_gpt/company_profiles");
  };
  const companies = () => {
    if (searchedOrganizations?.filtered?.length > 0)
      return searchedOrganizations?.filtered;
    else if (
      !searchedOrganizations.text &&
      searchedOrganizations?.filtered?.length === 0
    )
      return organizations?.repositories;
    else return null;
  };

  return (
    <div className={classes.root}>
      <List
        component="nav"
        aria-label="main mailbox folders"
        style={{ padding: "8px 12px 8px 0px" }}
      >
        {companies() ? (
          companies().map((organizations, i) => {
            return (
              <ListItem
                key={organizations?._id}
                button
                selected={selectedID === organizations?._id}
                onClick={(event) =>
                  handleListItemClick(event, organizations?._id, organizations)
                }
                style={{ borderRadius: "8px", margin: "5px" }}
              >
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        fontSize: 13,
                        fontWeight:
                          selectedID === organizations?._id ? 500 : 400,
                      }}
                    >
                      {organizations?.name}
                    </Typography>
                  }
                />
              </ListItem>
            );
          })
        ) : (
          <Typography style={{ textAlign: "center", fontSize: 14 }}>
            No Company found.
          </Typography>
        )}
      </List>
    </div>
  );
}
