import React from "react";
import { Bar } from "react-chartjs-2";

function EnergyCharts({ isPeer = false, kpiChartdata }) {
  const labels = kpiChartdata && [
    ...Object.entries(kpiChartdata).map(([key, val],i) => key.trim().split(' ')),
  ];
  const data = {
    labels,

    datasets: [
      //   {
      //     label: "Quarter 1",
      //     data: [kpiChartdata[1]],
      //     backgroundColor: "rgb(0, 0, 100, 0.9)",
      //     borderWidth: 1,
      //     barThickness: 50,
      //     borderRadius: 5,
      //   },
      {
        label: "Current Financial Year",
        data: kpiChartdata && [
          ...Object.entries(kpiChartdata).map(([key, val]) => val),
        ],
        backgroundColor: "rgb(0, 0, 100, 0.7)",
        borderWidth: 1,
        barThickness: 50,
        borderRadius: 5,
      },
      //   {
      //     label: "Quarter 3",
      //     data: [kpiChartdata[1]],
      //     backgroundColor: "rgb(0, 0, 100, 0.5)",
      //     borderWidth: 1,
      //     barThickness: 50,
      //     borderRadius: 5,
      //   },
      //   {
      //     label: "Quarter 4",
      //     data: [kpiChartdata[1]],
      //     backgroundColor: "rgb(0, 0, 100, 0.3)",
      //     borderWidth: 1,
      //     barThickness: 50,
      //     borderRadius: 5,
      //   },
    ],
  };
  const options = {
    plugins: {
      title: {
        display: true,
        text: 'Current Financial Year',
        //   position:"left"
      },
      legend: {
        display: false,
      },
    },
    // legend: {
    //   display: false,
    // },
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem) {
    //       return tooltipItem.yLabel;
    //     },
    //   },
    // },
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //   x: {
    //     // stacked: true,
    //   },
    //   y: {
    //     // stacked: true,
    //   },
    // },
  };
  return (
    <div style={{ height: "100%", width: "95%" }}>
      {kpiChartdata && (
        <Bar style={{ height: "100%" }} options={options} data={data} />
      )}
    </div>
  );
}
// function EnergyCharts({ isPeer = false, kpiChartdata }) {
//   const labels = ["Current Financial Year"];
//   const data = {
//     labels,
//     datasets: [
//     //   {
//     //     label: "Quarter 1",
//     //     data: [kpiChartdata[1]],
//     //     backgroundColor: "rgb(0, 0, 100, 0.9)",
//     //     borderWidth: 1,
//     //     barThickness: 50,
//     //     borderRadius: 5,
//     //   },
//       {
//         label: "Current Financial Year",
//         data: [kpiChartdata[1]],
//         backgroundColor: "rgb(0, 0, 100, 0.7)",
//         borderWidth: 1,
//         barThickness: 50,
//         borderRadius: 5,
//       },
//     //   {
//     //     label: "Quarter 3",
//     //     data: [kpiChartdata[1]],
//     //     backgroundColor: "rgb(0, 0, 100, 0.5)",
//     //     borderWidth: 1,
//     //     barThickness: 50,
//     //     borderRadius: 5,
//     //   },
//     //   {
//     //     label: "Quarter 4",
//     //     data: [kpiChartdata[1]],
//     //     backgroundColor: "rgb(0, 0, 100, 0.3)",
//     //     borderWidth: 1,
//     //     barThickness: 50,
//     //     borderRadius: 5,
//     //   },
//     ],
//   };
//   const options = {
//     plugins: {
//       title: {
//         display: true,
//         text: kpiChartdata[0],
//         //   position:"left"
//       },
//     },

//     responsive: true,
//     maintainAspectRatio: false,
//     // scales: {
//     //   x: {
//     //     // stacked: true,
//     //   },
//     //   y: {
//     //     // stacked: true,
//     //   },
//     // },
//   };
//   return (
//     <div style={{ height: "100%", width: "95%" }}>
//       <Bar style={{ height: "100%" }} options={options} data={data} />
//     </div>
//   );
// }

export default EnergyCharts;
