import React from "react";
import { connect } from "react-redux";
import {
  getCompanyData,
  getControversyLinks,
  getElasticOrganizations,
  publishInsights,
  publishLinks,
  regenerateInsights,
  setCompanyData,
  updateCompanyData,
  updateControversyType,
} from "../../redux/actions/riskdashboard/riskDashboardActions";
import RiskDocument from "../../components/widgets/riskDashboard/riskDocument";
import {
  getControversyData,
  getCompanyInsights,
} from "../../redux/actions/riskEvaluator/riskEvaluatorActions";

function RiskDocumentCont(props) {
  return <RiskDocument {...props} />;
}

export const mapStateToProps = (store) => ({
  login: store.login,
  riskDashboard: store.riskDashboard,
  controversyData: store.riskEvaluator.controversyData,
  riskEvaluator: store.riskEvaluator,
});

export const mapDispatchToProps = (dispatch) => ({
  getElasticOrganizations: (token, limit, starting_after) => {
    dispatch(getElasticOrganizations(token, limit, starting_after));
  },
  getCompanyData: (token, company_id) => {
    dispatch(getCompanyData(token, company_id));
  },
  getControversyLinks: (token, isin, limit, startingAfter) => {
    dispatch(getControversyLinks(token, isin, limit, startingAfter));
  },
  getControversyData: (token, isin) => {
    dispatch(getControversyData(token, isin));
  },
  updateCompanyData: (token, company_id, answers, setAdminTabNo) => {
    dispatch(updateCompanyData(token, company_id, answers, setAdminTabNo));
  },
  updateControversyType: (
    token,
    _id,
    controversy_type,
    isin,
    setCurrentLink
  ) => {
    dispatch(
      updateControversyType(token, _id, controversy_type, isin, setCurrentLink)
    );
  },
  publishLinks: (token, ids, isin, limit, startingAfter) => {
    dispatch(publishLinks(token, ids, isin, limit, startingAfter));
  },
  getCompanyInsights: (token, company_isin) => {
    dispatch(getCompanyInsights(token, company_isin));
  },
  regenerateInsights: (token, isin, company_id) => {
    dispatch(regenerateInsights(token, isin, company_id));
  },
  publishInsights: (token, isin, id) => {
    dispatch(publishInsights(token, isin, id));
  },
  setCompanyData: (payload) => {
    dispatch(setCompanyData(payload));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(RiskDocumentCont);
