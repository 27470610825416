import React from "react";
import {useSelector, useDispatch} from "react-redux"
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import EsgScore from "../esgCompanyProfile/esgScore";
import PercentageDoughnutChart from "../../UI/percentageDoughnut.js/percentageDoughnutChart";
import { Grid, Typography } from "@material-ui/core";
import charge from "../../../images/Folder.png";
import { EnvironmentalKpiChart } from "../charts/charts";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#EBF1F8",
    // color: theme.palette.common.black,
    fontWeight: 500,
    color: "#15314E",
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    color: "#15314E",
  },
}))(TableCell);

function createData(srNo, name, calories, fat, carbs, protein) {
  return { srNo, name, calories, fat, carbs, protein };
}

const rows = [
  createData("1. ", "Our People"),
  createData(
    "",
    "People with a Substantive Life Improvement (cumulative rounded)5",
    "3,588,000",
    "4,598,000",
    "5,878,000"
  ),
  createData("1.1 ", "Contribution to region"),
  createData("", "North America", "$15,973", "$18,925", "$42,239"),
  createData("", "Europe", "15,973", "18,925", "42,239"),
  createData("", "Cross-Region", "15,973", "18,925", "42,239"),
  createData("", "Growth Markets", "15,973", "18,925", "42,239"),
  createData("", "Total  Contributions", "15,973", "18,925", "42,239"),
  createData("1.2 ", "Contribution to region"),
  createData("", "Cash", "$15,973", "$18,925", "$42,239"),
  createData(
    "",
    "In-Kind (Accenture Development Partnerships and pro bono consulting)",
    "15,973",
    "18,925",
    "42,239"
  ),
  createData(
    "",
    "Cross-Time (Paid volunteering)",
    "15,973",
    "18,925",
    "42,239"
  ),
  createData("2. ", "community impact"),
  createData(
    "",
    "Global Headcount at Fiscal Year End (rounded)",
    "3,588,000",
    "4,598,000",
    "5,878,000"
  ),
  createData("2.1 ", "Employee Workforce Percentage by Gender2"),
  createData("", "North America", "$15,973", "$18,925", "$42,239"),
  createData("", "Total  Contributions", "15,973", "18,925", "42,239"),
  createData("2.2 ", "Contribution to region"),
  createData("", "Cash", "$15,973", "$18,925", "$42,239"),
  createData(
    "",
    "Cross-Time (Paid volunteering)",
    "15,973",
    "18,925",
    "42,239"
  ),
  createData("2.3 ", "Employee Workforce Percentage by Gender2"),
  createData("", "North America", "$15,973", "$18,925", "$42,239"),
  createData("", "Total  Contributions", "15,973", "18,925", "42,239"),
  createData("2.4 ", "Contribution to region"),
  createData("", "Cash", "$15,973", "$18,925", "$42,239"),
  createData(
    "",
    "Cross-Time (Paid volunteering)",
    "15,973",
    "18,925",
    "42,239"
  ),
];

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    marginTop: "10px",
    width: "100%",
  },
  tableCell: {
    padding: "5px",
  },
  titleRow: {
    backgroundColor: "#F6F6F6",
  },
});

export default function DataBreakdown({ scores,riskEvaluator }) {
  const classes = useStyles();
  const {organizationDetails} = useSelector(state => state.brmData)

  console.log('props',organizationDetails  )
  return (
    <div className="scroll" style={{height:'55vh',paddingBottom:20 }}>
      <Grid>
         <EsgScore scores={scores} />
      </Grid>
      <Grid
        md={12}
        sm={12}
        style={{
          paddingBotttom: "20px",
          // height: "60vh",
          // width:'100%'
          
        }}
      >
        <EnvironmentalKpiChart isin={organizationDetails[0]?.isin} />
      </Grid>

      {/* <TableContainer
        style={{
          //   backgroundColor: "#EBF1F8",
          padding: "3px",

          height: "55vh",
        }}
        component={"div"}
      >
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow style={{}}>
              <StyledTableCell className={classes.tableCell}>
                Topics
              </StyledTableCell>
              <StyledTableCell className={classes.tableCell} align="right">
                FY 20
              </StyledTableCell>
              <StyledTableCell className={classes.tableCell} align="right">
                FY 21
              </StyledTableCell>
              <StyledTableCell className={classes.tableCell} align="right">
                FY 22
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                className={row.srNo ? classes.titleRow : classes.topicRow}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: row.srNo ? 10 : 8,
                  }}
                >
                  {row.srNo && (
                    <Avatar
                      style={{
                        width: "30px",
                        height: "30px",
                        fontSize: "14px",
                        background: "#FEFEFE",
                        color: "#15314E",
                        marginRight: "10px",
                      }}
                    >
                      {row.srNo}
                    </Avatar>
                  )}
                  {row.name}
                </StyledTableCell>
                <StyledTableCell className={classes.tableCell} align="right">
                  {row.calories}
                </StyledTableCell>
                <StyledTableCell className={classes.tableCell} align="right">
                  {row.fat}
                </StyledTableCell>
                <StyledTableCell className={classes.tableCell} align="right">
                  {row.carbs}
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
    </div>
  );
}
