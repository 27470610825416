import ReactDOM from "react-dom";
import cubejs from "@cubejs-client/core";
import { QueryRenderer } from "@cubejs-client/react";
import React from "react";
import { Line, Bar, Pie } from "react-chartjs-2";
import { useDeepCompareMemo } from "use-deep-compare";
import { Grid, Paper, Typography } from "@material-ui/core";

const COLORS_SERIES = [
  "#5b8ff9",
  "#5ad8a6",
  "#5e7092",
  "#f6bd18",
  "#6f5efa",
  "#6ec8ec",
  "#945fb9",
  "#ff9845",
  "#299796",
  "#fe99c3",
];
const PALE_COLORS_SERIES = [
  "#d7e3fd",
  "#daf5e9",
  "#d6dbe4",
  "#fdeecd",
  "#dad8fe",
  "#dbf1fa",
  "#e4d7ed",
  "#ffe5d2",
  "#cce5e4",
  "#ffe6f0",
];
const commonOptions = {
  plugins: {
    title: {
      display: false,
      text: "Total Energy (MegaJoules)",
      //   position:"left"
    },
  },
  responsive: true,
  interaction: {
    intersect: false,
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};
// const commonOptions = {
//   maintainAspectRatio: true,
//   responsive: true,
//   interaction: {
//     intersect: false,
//   },
//   plugins: {
//     legend: {
//       position: "bottom",
//     },
//   },
//   scales: {
//     x: {
//       ticks: {
//         autoSkip: true,
//         maxRotation: 0,
//         padding: 12,
//         minRotation: 0,
//       },
//     },
//   },
// };
const commonLineOptions = {
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        maxRotation: 0,
        padding: 12,
        minRotation: 0,
      },
    },
  },
};

const useDrilldownCallback = ({
  datasets,
  labels,
  onDrilldownRequested,
  pivotConfig,
}) => {
  return React.useCallback(
    (elements) => {
      if (elements.length <= 0) return;
      const { datasetIndex, index } = elements[0];
      const { yValues } = datasets[datasetIndex];
      const xValues = [labels[index]];

      if (typeof onDrilldownRequested === "function") {
        onDrilldownRequested(
          {
            xValues,
            yValues,
          },
          pivotConfig
        );
      }
    },
    [datasets, labels, onDrilldownRequested]
  );
};

const LineChartRenderer = ({
  resultSet,
  pivotConfig,
  onDrilldownRequested,
}) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        yValues: [s.key],
        borderColor: COLORS_SERIES[index],
        pointRadius: 1,
        tension: 0.1,
        pointHoverRadius: 1,
        borderWidth: 2,
        tickWidth: 1,
        fill: false,
      })),
    [resultSet, pivotConfig]
  );
  const data = {
    //change labels here
    labels: resultSet.categories(pivotConfig).map((c) => c.x),
    datasets,
  };

  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    pivotConfig,
    onDrilldownRequested,
  });

  return (
    <Line
      type="line"
      data={data}
      options={data.datasets.length > 5 ? commonLineOptions : commonOptions}
      getElementAtEvent={getElementAtEvent}
    />
  );
};

const BarChartRenderer = ({ resultSet, pivotConfig, onDrilldownRequested }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        yValues: [s.key],
        backgroundColor: COLORS_SERIES[index],
        fill: false,
        borderWidth: 1,
        barThickness: 30,
        borderRadius: 5,
      })),
    [resultSet, pivotConfig]
  );

  const data = {
    labels: resultSet.categories(pivotConfig).map((c) => {
      if (c.x.includes(".")) {
        return c.x.split(".")[1];
      } else {
        return c.x;
      }
    }),
    datasets,
  };
  const stacked = !(pivotConfig.x || []).includes("measures");
  let options = {};
  if (data.datasets.length > 5) {
    options = {
      ...commonLineOptions,
      scales: {
        x: { ...commonLineOptions.scales.x, stacked },
        y: { ...commonLineOptions.scales.y, stacked },
      },
    };
  } else {
    options = {
      ...commonOptions,
      scales: {
        x: { ...commonOptions.scales.x, stacked },
        y: { ...commonOptions.scales.y, stacked },
      },
    };
  }

  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    onDrilldownRequested,
    pivotConfig,
  });
  return (
    <Bar
      type="bar"
      data={data}
      options={options}
      getElementAtEvent={getElementAtEvent}
    />
  );
};

const AreaChartRenderer = ({
  resultSet,
  pivotConfig,
  onDrilldownRequested,
}) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series(pivotConfig).map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        yValues: [s.key],
        pointRadius: 1,
        pointHoverRadius: 1,
        backgroundColor: PALE_COLORS_SERIES[index],
        borderWidth: 0,
        fill: true,
        tension: 0,
      })),
    [resultSet, pivotConfig]
  );
  const data = {
    labels: resultSet.categories(pivotConfig).map((c) => c.x),
    datasets,
  };
  const options = {
    ...commonOptions,
    scales: {
      ...commonOptions.scales,
      y: {
        stacked: true,
      },
    },
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    pivotConfig,
    onDrilldownRequested,
  });
  return (
    <Line
      type="area"
      data={data}
      options={options}
      getElementAtEvent={getElementAtEvent}
    />
  );
};

const PieChartRenderer = ({ resultSet, pivotConfig, onDrilldownRequested }) => {
  const data = {
    labels: resultSet.categories(pivotConfig).map((c) => c.x),
    datasets: resultSet.series(pivotConfig).map((s) => ({
      label: s.title,
      data: s.series.map((r) => r.value),
      yValues: [s.key],
      backgroundColor: COLORS_SERIES,
      hoverBackgroundColor: COLORS_SERIES,
    })),
  };
  const getElementAtEvent = useDrilldownCallback({
    datasets: data.datasets,
    labels: data.labels,
    pivotConfig,
    onDrilldownRequested,
  });
  return (
    <Pie
      type="pie"
      data={data}
      options={commonOptions}
      getElementAtEvent={getElementAtEvent}
    />
  );
};

const formatTableData = (columns, data) => {
  function flatten(columns = []) {
    return columns.reduce((memo, column) => {
      if (column.children) {
        return [...memo, ...flatten(column.children)];
      }

      return [...memo, column];
    }, []);
  }

  const typeByIndex = flatten(columns).reduce((memo, column) => {
    return { ...memo, [column.dataIndex]: column };
  }, {});

  function formatValue(value, { type, format } = {}) {
    if (value == undefined) {
      return value;
    }

    if (type === "boolean") {
      if (typeof value === "boolean") {
        return value.toString();
      } else if (typeof value === "number") {
        return Boolean(value).toString();
      }

      return value;
    }

    if (type === "number" && format === "percent") {
      return [parseFloat(value).toFixed(2), "%"].join("");
    }

    return value.toString();
  }

  function format(row) {
    return Object.fromEntries(
      Object.entries(row).map(([dataIndex, value]) => {
        return [dataIndex, formatValue(value, typeByIndex[dataIndex])];
      })
    );
  }

  return data.map(format);
};

// const TableRenderer = ({ resultSet, pivotConfig }) => {
//   const [tableColumns, dataSource] = useDeepCompareMemo(() => {
//     const columns = resultSet.tableColumns(pivotConfig);
//     return [
//       columns,
//       formatTableData(columns, resultSet.tablePivot(pivotConfig)),
//     ];
//   }, [resultSet, pivotConfig]);
//   return (
//     <Table pagination={false} columns={tableColumns} dataSource={dataSource} />
//   );
// };

const cubejsApi = cubejs(process.env.CUBEJS_API_SECRET, {
  apiUrl: "https://cube.gieom.datahub.imgrows.app/cubejs-api/v1",
});

const renderChart = ({
  resultSet,
  error,
  pivotConfig,
  onDrilldownRequested,
}) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <>spin...</>;
  }

  return (
    <BarChartRenderer
      resultSet={resultSet}
      pivotConfig={pivotConfig}
      onDrilldownRequested={onDrilldownRequested}
    />
  );
};
const renderLineChart = ({
  resultSet,
  error,
  pivotConfig,
  onDrilldownRequested,
}) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <>spin...</>;
  }

  return (
    <LineChartRenderer
      resultSet={resultSet}
      pivotConfig={pivotConfig}
      onDrilldownRequested={onDrilldownRequested}
    />
  );
};

export const ChartRenderer = () => {
  return (
    <>
      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>Overall ESG Score Company wise</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: ["final_ESG_score.final_ESG_score"],
              dimensions: ["final_ESG_score.company_name"],
              timeDimensions: [],
              order: {
                "final_ESG_score.final_ESG_score": "desc",
              },
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderChart({
                ...props,
                chartType: "table",
                pivotConfig: {
                  x: ["final_ESG_score.company_name"],
                  y: ["measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>

      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>ESG Score Company Wise</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: ["ESG.ESG_score"],
              dimensions: ["ESG.company_name"],
              timeDimensions: [],
              order: {
                "ESG.ESG_score": "desc",
              },
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderChart({
                ...props,
                chartType: "bar",
                pivotConfig: {
                  x: ["ESG.company_name"],
                  y: ["measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>

      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>ESGC Score Company Wise</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: ["Controversy.ESGC_score"],
              dimensions: ["Controversy.company_name"],
              timeDimensions: [],
              order: {
                "Controversy.ESGC_score": "desc",
              },
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderChart({
                ...props,
                chartType: "bar",
                pivotConfig: {
                  x: ["Controversy.company_name"],
                  y: ["measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>

      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>Environmental KPI Score Company-wise</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: [
                "Environmental_KPI.energy",
                "Environmental_KPI.social",
                "Environmental_KPI.waste",
                "Environmental_KPI.analytic_co2",
                "Environmental_KPI.green_revenues",
              ],
              dimensions: ["Environmental_KPI.company_name"],
              timeDimensions: [],
              order: {
                "Environmental_KPI.energy": "desc",
              },
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderChart({
                ...props,
                chartType: "bar",
                pivotConfig: {
                  x: ["Environmental_KPI.company_name"],
                  y: ["measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>

      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>Total Carbon Footprint Company-wise</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              limit: 5000,
              dimensions: ["Environmental_KPI.company_name"],
              order: {
                "Environmental_KPI.analytic_co2": "desc",
              },
              measures: ["Environmental_KPI.analytic_co2"],
              timeDimensions: [],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderChart({
                ...props,
                chartType: "bar",
                pivotConfig: {
                  x: ["Environmental_KPI.company_name"],
                  y: ["measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>

      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>Contoverst Count Company-wise</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: ["Controversy_count.Controversy_count"],
              dimensions: ["Controversy_count.company_name"],
              timeDimensions: [],
              order: {
                "Controversy_count.Controversy_count": "desc",
              },
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderChart({
                ...props,
                chartType: "bar",
                pivotConfig: {
                  x: ["Controversy_count.company_name"],
                  y: ["measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>



      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>Total Controversy counts</Typography>
        <div style={{ padding: "5px", height: "450px" }}>
          <QueryRenderer
            query={{
              measures: [
                "Controversy_count.Anti_competition_controversy",
                "Controversy_count.business_controversy",
                "Controversy_count.Intellectual_property_controversies",
                "Controversy_count.Critical_countries_controversies",
                "Controversy_count.Public_health_controversies",
                "Controversy_count.Tax_fraud_controversies",
                "Controversy_count.Child_labour_controversie",
                "Controversy_count.Human_rights_controversies",
                "Controversy_count.Management_compensation_controversies_count",
                "Controversy_count.Consumer_controversies",
                "Controversy_count.Customer_health_and_safety_controversies",
                "Controversy_count.Privacy_controversies",
                "Controversy_count.Product_access_controversies",
                "Controversy_count.Responsible_marketing_controversies",
                "Controversy_count.Responsible_R_and_D_controversies",
                "Controversy_count.Environmental_controversies",
                "Controversy_count.Accounting_controversies_coun",
                "Controversy_count.Insider_dealings_controversies",
                "Controversy_count.Shareholder_rights_controversies",
                "Controversy_count.Diversity_and_opportunity_controversies",
                "Controversy_count.Employee_health_and_safety_controversies",
                "Controversy_count.Wages_or_working_conditions_controversies",
                "Controversy_count.Strikes",
              ],
              dimensions: ["Controversy_count.company_name"],
              order: {
                "Controversy_count.Anti_competition_controversy": "desc",
              },
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderChart({
                ...props,
                chartType: "bar",
                pivotConfig: {
                  x: ["Controversy_count.company_name"],
                  y: ["measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>

      {/* time-wise charts */}
      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>Overall ESG Score Time-series</Typography>

        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: ["Overall_ESG_score.final_esg_score"],
              dimensions: [
                "Overall_ESG_score.company_name",
                "Overall_ESG_score.year",
              ],
              order: [
                ["Overall_ESG_score.year", "asc"],
                ["Overall_ESG_score.final_esg_score", "desc"],
              ],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderLineChart({
                ...props,
                chartType: "line",
                pivotConfig: {
                  x: ["Overall_ESG_score.year"],
                  y: ["Overall_ESG_score.company_name", "measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>
      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>ESG Score Time-series</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: ["ESG.ESG_score"],
              dimensions: ["ESG.company_name", "ESG.year"],
              order: [
                ["ESG.year", "asc"],
                ["ESG.ESG_score", "desc"],
              ],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderLineChart({
                ...props,
                chartType: "line",
                pivotConfig: {
                  x: ["ESG.year"],
                  y: ["ESG.company_name", "measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>
      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>ESGC Score Time-series</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: ["Controversy.ESGC_score"],
              dimensions: ["Controversy.company_name", "Controversy.year"],
              order: [
                ["Controversy.year", "asc"],
                ["Controversy.ESGC_score", "desc"],
              ],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderLineChart({
                ...props,
                chartType: "line",
                pivotConfig: {
                  x: ["Controversy.year"],
                  y: ["Controversy.company_name", "measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>
      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>KPI Scores Time-series</Typography>
        <div style={{ padding: "5px", height: "460px" }}>
          <QueryRenderer
            query={{
              limit: 5000,
              measures: [
                "Environmental_KPI.energy",
                "Environmental_KPI.social",
                "Environmental_KPI.waste",
                "Environmental_KPI.analytic_co2",
                "Environmental_KPI.green_revenues",
              ],
              dimensions: [
                "Environmental_KPI.year",
                "Environmental_KPI.company_name",
              ],
              order: [
                ["Environmental_KPI.year", "asc"],
                ["Environmental_KPI.energy", "desc"],
              ],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderLineChart({
                ...props,
                chartType: "line",
                pivotConfig: {
                  x: ["Environmental_KPI.year"],
                  y: ["Environmental_KPI.company_name", "measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>
      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        <Typography>Total Carbon Footprint Time-series</Typography>
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              limit: 5000,
              dimensions: [
                "Environmental_KPI.year",
                "Environmental_KPI.company_name",
              ],
              order: [
                ["Environmental_KPI.year", "asc"],
                ["Environmental_KPI.analytic_co2", "desc"],
              ],
              measures: ["Environmental_KPI.analytic_co2"],
              timeDimensions: [],
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderLineChart({
                ...props,
                chartType: "bar",
                pivotConfig: {
                  x: ["Environmental_KPI.year"],
                  y: ["Environmental_KPI.company_name", "measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>
    </>
  );
};

export const EnvironmentalKpiChart = ({ isin }) => {
  return (
    <div style={{ width: "100%" }}>
      {/* {isin && (
        <QueryRenderer
          query={{
            measures: [
              "Environmental_KPI.energy",
              "Environmental_KPI.social",
              "Environmental_KPI.waste",
              "Environmental_KPI.analytic_co2",
              "Environmental_KPI.green_revenues",
            ],
            dimensions: ["Environmental_KPI.year"],
            order: [
              ["Environmental_KPI.year", "asc"],
              ["Environmental_KPI.energy", "desc"],
            ],
            filters: [
              {
                member: "Environmental_KPI.datastreamid",
                operator: "equals",
                values: ["INE075A01022"],
              },
            ],
          }}
          cubejsApi={cubejsApi}
          resetResultSetOnChange={false}
          render={(props) =>
            renderChart({
              ...props,
              chartType: "line",
              pivotConfig: {
                x: ["Environmental_KPI.year"],
                y: ["measures"],
                fillMissingDates: true,
                joinDateRange: false,
              },
            })
          }
        />
      )} */}
      {isin && (
        <Grid
          md={12}
          lg={12}
          sm={12}
          component={Paper}
          style={{ padding: "5px" }}
        >
          <Typography
            style={{ padding: "15px 0px 10px 0px", display: "inline-block" }}
          >
            Environmental KPI Score
          </Typography>
          <div style={{ padding: "5px", height: "400px" }}>
            <QueryRenderer
              query={{
                measures: [
                  "Environmental_KPI.energy",
                  "Environmental_KPI.social",
                  "Environmental_KPI.waste",
                  "Environmental_KPI.analytic_co2",
                  "Environmental_KPI.green_revenues",
                ],
                dimensions: ["Environmental_KPI.company_name"],
                order: {
                  "Environmental_KPI.energy": "desc",
                },
                filters: [
                  {
                    member: "Environmental_KPI.datastreamid",
                    operator: "equals",
                    values: [isin],
                  },
                ],
              }}
              cubejsApi={cubejsApi}
              resetResultSetOnChange={false}
              render={(props) =>
                renderChart({
                  ...props,
                  chartType: "bar",
                  pivotConfig: {
                    x: ["measures"],
                    y: ["Environmental_KPI.company_name"],
                    fillMissingDates: true,
                    joinDateRange: false,
                  },
                })
              }
            />
          </div>
        </Grid>
      )}
      {isin && (
        <Grid
          md={12}
          lg={12}
          sm={12}
          component={Paper}
          style={{ padding: "5px" }}
        >
          <Typography
            style={{ padding: "15px 0px 10px 0px", display: "inline-block" }}
          >
            Social KPI Score
          </Typography>
          <div style={{ padding: "5px", height: "400px" }}>
            <QueryRenderer
              query={{
                measures: [
                  "Social_KPI.diversity",
                  "Social_KPI.human_Rights",
                  "Social_KPI.data_Privacy",
                  "Social_KPI.career",
                  "Social_KPI.product",
                  "Social_KPI.health",
                ],
                filters: [
                  {
                    member: "Social_KPI.datastreamid",
                    operator: "equals",
                    values: [isin],
                  },
                ],
                dimensions: ["Social_KPI.company_name"],
                timeDimensions: [],
                order: {
                  "Social_KPI.diversity": "desc",
                },
              }}
              cubejsApi={cubejsApi}
              resetResultSetOnChange={false}
              render={(props) =>
                renderChart({
                  ...props,
                  chartType: "bar",
                  pivotConfig: {
                    x: ["measures"],
                    y: ["Social_KPI.company_name"],
                    fillMissingDates: true,
                    joinDateRange: false,
                  },
                })
              }
            />
          </div>
        </Grid>
      )}
      {isin && (
        <Grid
          md={12}
          lg={12}
          sm={12}
          component={Paper}
          style={{ padding: "5px" }}
        >
          <Typography
            style={{ padding: "15px 0px 10px 0px", display: "inline-block" }}
          >
            Governance KPI Score
          </Typography>
          <div style={{ padding: "5px", height: "400px" }}>
            <QueryRenderer
              query={{
                measures: [
                  "Governance_KPI.structure",
                  "Governance_KPI.compensation",
                ],
                filters: [
                  {
                    member: "Governance_KPI.datastreamid",
                    operator: "equals",
                    values: [isin],
                  },
                ],
                dimensions: ["Governance_KPI.company_name"],
                timeDimensions: [],
                order: {
                  "Governance_KPI.structure": "desc",
                },
              }}
              cubejsApi={cubejsApi}
              resetResultSetOnChange={false}
              render={(props) =>
                renderChart({
                  ...props,
                  chartType: "bar",
                  pivotConfig: {
                    x: ["measures"],
                    y: ["Governance_KPI.company_name"],
                    fillMissingDates: true,
                    joinDateRange: false,
                  },
                })
              }
            />
          </div>
        </Grid>
      )}
    </div>
  );
};
export const EsgAnainsPeersChart = ({ isin }) => {
  return (
    <div style={{ width: "100%" }}>
      <Grid
        md={12}
        lg={12}
        sm={12}
        component={Paper}
        style={{ padding: "5px" }}
      >
        {/* <Typography
            style={{ padding: "15px 0px 10px 0px", display: "inline-block" }}
          >
            Environmental KPI Score
          </Typography> */}
        <div style={{ padding: "5px", height: "400px" }}>
          <QueryRenderer
            query={{
              measures: [
                "all_scores.environment",
                "all_scores.social",
                "all_scores.governance",
              ],
              dimensions: ["all_scores.company_name"],
              order: {
                "all_scores.environment": "desc",
              },
            }}
            cubejsApi={cubejsApi}
            resetResultSetOnChange={false}
            render={(props) =>
              renderChart({
                ...props,
                chartType: "bar",
                pivotConfig: {
                  x: ["all_scores.company_name"],
                  y: ["measures"],
                  fillMissingDates: true,
                  joinDateRange: false,
                },
              })
            }
          />
        </div>
      </Grid>
    </div>
  );
};
