import encrypt from "../../../redux/actions/shared/sharedActions";
import UNIVERSAL from "../../../config";
import { set_loader, unset_loader } from "../loader/loader_action";
import {
  SET_ACKNOWLEDGED,
  SET_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_NEW_ORGANIZATION_LOGO,
  SET_CHAT_QUESTION,
  SET_CHAT_ANSWER,
  SET_CHATS_CONVERSATION,
  SET_CHAT_GROUP,
  SET_SELECTED_CHAT,
  SET_SEARCHED_ORGANIZATION,
  SET_SEARCHED_TEXT,
} from "../../../constants/esgGptConstant";

export const setSearchedOrg = (payload) => {
  return {
    type: SET_SEARCHED_ORGANIZATION,
    payload,
  };
};
export const setSearcheText = (payload) => {
  return {
    type: SET_SEARCHED_TEXT,
    payload,
  };
};
export const setAcknowledged = (payload) => {
  return {
    type: SET_ACKNOWLEDGED,
    payload,
  };
};

export const setOrganization = (payload) => {
  return {
    type: SET_ORGANIZATION,
    payload,
  };
};

export const setNewCompanyDetails = (payload) => {
  return {
    type: SET_NEW_ORGANIZATION,
    payload,
  };
};

export const setNewCompanyLogo = (payload) => {
  return {
    type: SET_NEW_ORGANIZATION_LOGO,
    payload,
  };
};

export const setChatQuestion = (payload) => {
  return {
    type: SET_CHAT_QUESTION,
    payload,
  };
};

export const setChatAnswer = (payload) => {
  return {
    type: SET_CHAT_ANSWER,
    payload,
  };
};

export const setChatConversations = (payload) => {
  return {
    type: SET_CHATS_CONVERSATION,
    payload,
  };
};

export const setChatGroups = (payload) => {
  return {
    type: SET_CHAT_GROUP,
    payload,
  };
};

export const setSelectedChat = (payload) => {
  return {
    type: SET_SELECTED_CHAT,
    payload,
  };
};

export const createOrganizationEsgGpt = (
  token,
  name,
  isin,
  sector,
  industry,
  industry_code,
  logo
) => {
  return (dispatch) => {
    dispatch(set_loader);
    var formData = new FormData();
    const data = encrypt({
      "user-token": token,
      name,
      isin,
      sector,
      industry,
      industry_code,
      // logo,
    });

    formData.append("data", data);
    formData.append("logo", logo);

    return fetch(
      UNIVERSAL.BASEURL + "/organization/create_organization_esg_gpt",
      {
        method: "POST",

        body: formData,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setAcknowledged(responseJson?.status));
          dispatch(setNewCompanyDetails(responseJson?.result));
          dispatch(viewOrganizationEsgGpt(token));
        }

        dispatch(unset_loader);
      });
  };
};
export const deleteOrganizationEsgGpt = (token, id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      id,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/organization/delete_organization_esg_gpt",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson);
          // dispatch(set_snack_bar(responseJson.status.responseJson.message));
          // dispatch(setNewCompanyDetails(responseJson?.result));
          dispatch(viewOrganizationEsgGpt(token));
        }

        dispatch(unset_loader);
      });
  };
};

export const viewOrganizationEsgGpt = (token) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/organization/view_organization_esg_gpt",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganization(responseJson?.result));
          // responseJson?.result?.favorites.length > 0
          //   ? dispatch(setNewCompanyDetails(responseJson?.result?.favorites[0]))
          //   : dispatch(
          //       setNewCompanyDetails(responseJson?.result?.repositories[0])
          //     );
        }

        dispatch(unset_loader);

        dispatch(unset_loader);
      });
  };
};

export const getGptAnswer = (
  token,
  company_id,
  question,
  chat_id,
  is_company_based
) => {
  return (dispatch, getState) => {
    // dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      question,
      company_id,
      chat_id,
      is_company_based,
    });

    const { chats } = getState().esgGptReducer;

    const chatObj = { question, isLoading: true, answer: "" };

    dispatch(setChatConversations([...chats, chatObj]));

    return fetch(UNIVERSAL.BASEURL + "/python_apis/get_answer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          const { chats } = getState().esgGptReducer;

          const filterChats = chats.filter((c) => !c.isLoading);

          dispatch(
            setChatConversations([
              ...filterChats,
              {
                ...chatObj,
                isLoading: false,
                answer: responseJson?.result,
              },
            ])
          );

          if (!is_company_based && !chat_id) {
            dispatch(viewChatGroups(token));
          }
        }

        // dispatch(unset_loader);

        // dispatch(unset_loader);
      });
  };
};

export const getGptChatHIstory = (
  token,
  company_id,
  is_company_based,
  chat_id
) => {
  return (dispatch, getState) => {
    // dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      is_company_based,
      chat_id,
      company_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/get_chat_history ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setChatConversations(responseJson?.result));
        }

        // dispatch(unset_loader);

        // dispatch(unset_loader);
      });
  };
};

export const viewChatGroups = (token) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/get_chat_groups ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setChatGroups(responseJson?.result.reverse()));
        }
      });
  };
};

export const deleteChat = (token, chat_id) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      chat_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/delete_chat_group ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewChatGroups(token));
        }
      });
  };
};

export const updateChatName = (token, chat_id, group_name) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      chat_id,
      group_name,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/update_chat_group_name  ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // console.log(responseJson);
          dispatch(viewChatGroups(token));
        }
      });
  };
};
