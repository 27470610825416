import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";

// const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: (props) => props.width- 60,
  },
  drawerPaper: {
    width: (props) => props.width ,
    display: "flex",
    justifyContent: "flex-start",
    border: "1px solid #EBF1F8",
  },
  // necessary for content to be below app bar
}));

export default function EsgGptMainLeft({
  drawerTabs,
  drawerSearchBar,
  drawerCompanyList,
  drawerMoreButton,
  drawerAddCompanyButton,
  children,
  ...props
}) {
  const classes = useStyles(props);

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      {drawerTabs}
      {drawerSearchBar}
      {drawerAddCompanyButton}
      {drawerCompanyList && (
        <div
          className="scroll"
          style={{
            height: window.height > 1000 ? "64vh" : "56vh",
          }}
        >
          {drawerCompanyList}
        </div>
      )}
      {drawerMoreButton && (
        <div style={{ marginTop: "auto" }}>{drawerMoreButton}</div>
      )}
      {children && <div style={{ marginTop: "auto" }}>{children}</div>}
    </Drawer>
  );
}
