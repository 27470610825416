import React from "react";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Menu from "@material-ui/core/Menu";
import { Link } from "react-router-dom";
import userProfileCardUseStyles from "./userProfileCardStyles";

function UserProfileCard({
  userName,
  userEmail,
  token,
  logOut,
  loginDetails,
  userProfile,
  anchorEl,
  onClose,
}) {
  const classes = userProfileCardUseStyles();
  return (
    <Menu
      id="user-profile-menu"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: loginDetails?.user_type === "gpt" ? "top" : "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={() => onClose()}
      className={classes.menu}
      getContentAnchorEl={null}
    >
      <Box className={classes.boxstyle}>
        <Box>
          <Typography variant="subtitle1" className={classes.typo}>
            {userName}
          </Typography>

          <Typography variant="body2">{userEmail}</Typography>
        </Box>
        <Avatar
          src={
            userProfile && userProfile.url
              ? userProfile.url
              : `https://avatars.dicebear.com/api/initials/${userName}.svg?bold=1`
          }
        />
      </Box>
      <div className={classes.divider}></div>
      <Link to="/login" className={classes.link}>
        <div
          className={classes.iconDiv}
          onClick={() => {
            logOut(token);
          }}
        >
          <Icon fontSize="small">logout</Icon>
          <Typography className={classes.typo1}>Logout</Typography>
        </div>
      </Link>
      <div className={classes.divider}></div>
    </Menu>
  );
}

export default React.memo(UserProfileCard);
