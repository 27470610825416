import React from "react";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptChatField from "../EsgGptChatField";

import { ModifiedList } from "../esgGptAddNewCompany/EsgGptUpdatedList";
import analytics from "../../../../images/Analytics.png";
import { ChatPlayGround } from "../esgGptCompanyProfile/EsgGptCompanyRight";
import { useSelector } from "react-redux";

function EsgGptChat(props) {
  const { selectedChat } = useSelector((state) => state.esgGptReducer);
  const [chatInitiate, setChatInitiate] = React.useState(false);
  const handleChat = () => {
    setChatInitiate(true);
  };
  return (
    <EsgGptlayout
      EsgCompanyList={<ModifiedList />}
      login={props.login}
      logOut={props.logOut}
    >
      <div
        style={{
          height: "88%",
          width: "100%",
          overflow: "scroll",
          marginBottom: "1%",
        }}
      >
        {Object.keys(selectedChat).length === 0  && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "70%",
            }}
          >
            <img src={analytics} alt="analytics" />
          </div>
        )}
        <div onClick={handleChat}>
          <ChatPlayGround />
        </div>
      </div>

      <EsgGptChatField />
    </EsgGptlayout>
  );
}

export default EsgGptChat;
