import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: "Total Energy (MegaJoules)",
      //   position:"left"
    },
  },

  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ["Current Financial year", "Previous Financial year"];

export const data = {
  labels,
  datasets: [
    {
      label: "Quarter 1",
      data: [200, 100, 300],
      backgroundColor: "rgb(0, 0, 100, 0.9)",
      borderWidth: 1,
      barThickness: 50,
      borderRadius: 5,
    },
    {
      label: "Quarter 2",
      data: [200, 100, 300],
      backgroundColor: "rgb(0, 0, 100, 0.7)",
      borderWidth: 1,
      barThickness: 50,
      borderRadius: 5,
    },
    {
      label: "Quarter 3",
      data: [500, 400, 300],
      backgroundColor: "rgb(0, 0, 100, 0.5)",
      borderWidth: 1,
      barThickness: 50,
      borderRadius: 5,
    },
    {
      label: "Quarter 4",
      data: [300, 100, 500],
      backgroundColor: "rgb(0, 0, 100, 0.3)",
      borderWidth: 1,
      barThickness: 50,
      borderRadius: 5,
    },
  ],
};

export default function Charts({ isPeer = false, peer_companies }) {
  const options1 = {
    plugins: {
      title: {
        display: false,
        text: "Total Energy (MegaJoules)",
        //   position:"left"
      },
    },

    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  const labels1 = ["Environment Scores", "Social Scores", "Governance Scores"];
  // peer_companies.map()
  
  const data1 = {
    labels:
      (peer_companies &&
        peer_companies.map(({ company_name }) => company_name)) ||
      [],
    datasets: [
      {
        label: "Environment Scores",
        data:
          (peer_companies &&
            peer_companies.map(({ environment_score }) => environment_score * 100)) ||
          [],
        backgroundColor: "rgb(255, 99, 132)",
        borderWidth: 1,
        barThickness: 70,
        borderRadius: 5,
      },
      {
        label: "Social Scores",
        data:
          (peer_companies &&
            peer_companies.map(({ social_score }) => social_score * 100)) ||
          [],
        backgroundColor: "rgb(255, 159, 64)",
        borderWidth: 1,
        barThickness: 70,
        borderRadius: 5,
      },
      {
        label: "Governance Scores",
        data:
          (peer_companies &&
            peer_companies.map(
              ({ governance_score }) => governance_score * 100
            )) ||
          [],
        backgroundColor: "rgb(75, 192, 192)",
        borderWidth: 1,
        barThickness: 70,
        borderRadius: 5,
      },
      // {
      //   label: "Company 4",
      //   data: [300, 100, 500, 200],
      //   backgroundColor: "rgb(153, 102, 255)",
      //   borderWidth: 1,
      //   barThickness: 70,
      //   borderRadius: 5,
      // },
    ],
  };

  return (
    <div style={{ height: "100%",  }}>
      <Bar
        style={{ height: "100%" }}
        options={isPeer ? options1 : options}
        data={isPeer ? data1 : data}
      />
    </div>
  );
}
