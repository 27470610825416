import React, { useEffect, useRef, useState } from "react";
import TabPanel from "../../UI/tabPanel/tabPanel";
import PublicReports from "./publicReports";
import DataBreakdown from "../riskAssessment/DataBreakdown";
import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Drawer,
  FormControl,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  MuiThemeProvider,
  OutlinedInput,
  Select,
  Tab,
  Tabs,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { appTheme } from "../riskAssessment/brmDetaails";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomButton from "../../UI/button/button";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";

function Documents({
  showPeerDocs,
  companyName,
  setShowpeerDocs,
  brmData,
  getPublicReports,
  login,
  ...props
}) {
  console.log("riskEvaluator", props.riskEvaluator);
  const docStyles = makeStyles((theme) => ({
    grid2: { marginTop: 15 },
    grid3: { display: "flex", justifyContent: "flex-end" },
    grid4: { paddingLeft: 75 },
    grid5: { paddingRight: 75 },
    grid6: { paddingRight: 10 },
    grid7: { marginTop: 20 },

    AppBar: { boxShadow: "none", maxHeight: "25px" },
    tabLabel: {
      textTransform: "capitalize",
      fontSize: 14,
      fontFamily: "Poppins",
    },
    tab: {
      fontSize: 12,
    },
    apiCont: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      color: theme.palette.text.secondary,
      border: "1px solid lightgrey",
      borderRadius: "8px",
    },
  }));
  const classes = docStyles();
  const inputRef = useRef(null);

  const [reportsTab, setReportsTab] = useState(0);
  const [file, setFile] = React.useState([]);
  const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);

  const [fileData, setFileData] = useState({});
  const [isDatePickerDialogOpen, setIsDatePickerDialogOpen] = useState(false);

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearRange, setyearRange] = useState([]);
  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  const handleFileUpload = (e) => {
    const uploadFile = Object.values(e.target.files);
    console.log(uploadFile);
    uploadFile !== undefined &&
      setFileData((prev) => ({
        ...prev,
        file: uploadFile[0],
      }));
  };
  const handleFile = (e) => {
    const uploadFile = Object.values(e.target.files);
    uploadFile !== undefined && setFile([...file, ...uploadFile]);
  };

  useEffect(() => {
    getPublicReports(login.token, brmData?.organizationDetails[0]?._id);
  }, []);
  // console.log(fileData);
  //   useEffect(()=>{
  //     const copyfile = new Blob([file], { type: "application/pdf" });
  //     //Build a URL from the file
  //     const fileURL = URL.createObjectURL(copyfile);
  //     //Open the URL on new Window
  //     //  const pdfWindow = window.open();
  //     //  pdfWindow.location.href = fileURL;
  //     console.log('s',fileURL)
  //   },[file])

  const handleFileRemove = (value) => {
    setFile(value);
  };
  const range = (year) => {
    if (startYear === year) return true;
    if (endYear === year) return true;
    if (year <= endYear && year >= startYear) return true;
    else return false;
  };

  const handleYearBack = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] - 9 + i);
    }
    setyearRange(data);
  };

  const handleYearForward = () => {
    const data = [];
    for (let i = 0; i < 9; i++) {
      data.push(yearRange[0] + 9 + i);
    }
    setyearRange(data);
  };
  useEffect(() => {
    // console.log("new mount", fileData.year);
    fileData.year && setStartYear(fileData.year?.split("-")[0]);
    fileData.year && setEndYear(fileData.year?.split("-")[1]);
  }, [fileData.year]);
  const handleYearClick = (year) => {
    if (startYear && endYear) {
      // console.log("year", year, startYear, endYear);
      setStartYear(year);
      setEndYear();
      // console.log("setEndYear 2");
    } else if (!startYear) {
      setStartYear(year);
    } else if (year < startYear) {
      setStartYear(year);
      // console.log("setEndYear 3");
    } else if (year !== startYear) {
      setEndYear(year);
      // console.log("setEndYear 4");
    } else {
      // set_snack_bar(true,"Select a different Year")
      alert("Select a different year");
    }
  };
  useEffect(() => {
    const data = [];
    if (!fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(currentYear - 5 + i);
      }
      setyearRange(data);
    } else if (fileData.year) {
      for (let i = 0; i < 9; i++) {
        data.push(fileData.year.split("-")[0] - 3 + i);
      }
      setyearRange(data);
    }
  }, [isDatePickerDialogOpen]);
  const yearPickerDialog = () => {
    return (
      <>
        <Dialog maxWidth="lg" open={isDatePickerDialogOpen}>
          <DialogContent style={{ width: "100%" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`${startYear || ""}-${endYear || ""}`}</Typography>
              <Typography
                onClick={handleYearBack}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`<`}</Typography>
              <Typography
                onClick={handleYearForward}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                }}
              >{`>`}</Typography>
              <Typography
                onClick={() => {
                  setStartYear("");
                  setEndYear("");
                }}
                style={{
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600,
                  marginLeft: "15px",
                  marginBottom: "15px",
                  float: "right",
                }}
              >{`Clear`}</Typography>
            </div>
            <div>
              <Grid
                container
                xs={12}
                md={12}
                gap={1}
                spacing={2}
                style={{
                  padding: "2% 1% 4% 1%",
                  // // marginLeft: '2%',
                }}
              >
                {yearRange.map((year) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      md={4}
                      align="center"
                      style={{
                        // margin:'6px 8px',
                        // border: "1px solid blue",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        onClick={() => handleYearClick(year)}
                        style={{
                          fontWeight: 500,
                          padding: "8px 5px",
                          border: range(year) ? "1px solid #3374B9" : "none",
                          borderRadius: "5px",
                          backgroundColor: range(year) ? "#F0F6FD" : "inherit",
                        }}
                      >
                        {year}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <CustomButton
              // variant="outlined"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={() => {
                setFileData((prev) => ({
                  ...prev,
                  yearOfAssessment: "",
                }));
                fileData.year && setStartYear(fileData.year.split("-")[0]);
                fileData.year && setEndYear(fileData.year.split("-")[1]);
                setIsDatePickerDialogOpen(false);
              }}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="contained"
              color="primary"
              style={{ padding: "6px 20px", textTransform: "none" }}
              disabled={!startYear || !endYear}
              onClick={() => {
                setFileData((prev) => ({
                  ...prev,
                  year: `${startYear}-${endYear}`,
                }));
                setIsDatePickerDialogOpen(false);
              }}
            >
              Set Period
            </CustomButton>
          </DialogActions>
        </Dialog>
      </>
    );
  };
  const fileUploadDrawer = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => setIsUploadDrawerOpen(false)}
        // variant="persistent"
        width="lg"
        open={isUploadDrawerOpen}
      >
        <div style={{ width: "500px", padding: 10 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              Upload File
            </Typography>
            <Typography
              onClick={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              <CloseIcon style={{cursor:'pointer'}} />
            </Typography>
          </div>
          <div>
            <FormControl
              variant="outlined"
              style={{ width: "100%", margin: "15px 0px" }}
            >
              <InputLabel htmlFor="age-native-helper">
                Select File Type
              </InputLabel>{" "}
              <Select
                fullWidth
                size="small"
                // style={{
                //   border: "1px solid #C0D4E9",
                //   backgroundColor: "#FEFEFE",
                //   borderRadius: "8px",
                //   // height: "40px",
                //   // margin: "15px 0px",
                // }}
                // inputProps={{
                //   name: 'Select File Type',
                //   id: 'age-native-helper',
                // }}
                label="Select Type Of Report"
                // value={currentLink.controversy}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setFileData((prev) => ({
                    ...prev,
                    type: value,
                  }));
                }}
                // input={<OutlinedInput />}
              >
                {["Annual", "Sustainability", "Financial"].map((item) => {
                  return (
                    <MenuItem
                      key={item}
                      value={item}
                      style={{ backgroundColor: "white" }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box
              my={2}
              onClick={() => setIsDatePickerDialogOpen(true)}
              style={{
                border: "1px solid #00000050",
                padding: 12,
                borderRadius: 5,
                cursor: "pointer",
                minHeight: 45,
              }}
            >
              {fileData.year ? (
                <Typography variant="body1" name="yearOfAssesment">
                  {`${fileData.year.split("-")[0]} - ${
                    fileData.year.split("-")[1]
                  }`}
                </Typography>
              ) : (
                <Typography
                  style={{ color: "#878282e6", fontFamily: "Roboto" }}
                >
                  Year Of Assessment
                </Typography>
              )}

              {/* <Typography variant="body1" name="yearOfAssesment">
                    {basicInfo.yearOfAssessment[0].startDate !== "" &&
                    basicInfo.yearOfAssessment[0].endDate !== ""
                      ? `${moment(
                          new Date(basicInfo.yearOfAssessment[0].startDate)
                        ).format("DD MMM yy")} - ${moment(
                          new Date(basicInfo.yearOfAssessment[0].endDate)
                        ).format("DD MMM yy")}`
                      : "Year Of Assessment"}
                  </Typography> */}
            </Box>
            <Box
              width="100%"
              style={{
                margin: "26px 5px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              <AttachFileIcon
                style={{ backgroundColor: "#ced4da", padding: 4 }}
                color="disabled"
              />
              <input
                name="actUpload"
                id="contained-button-activityFile"
                type="file"
                accept=".pdf"
                ref={inputRef}
                onChange={(e) => handleFileUpload(e)}
                style={{ display: "none" }}
                multiple
              />
              <div
                style={{
                  borderBottom: "2px solid #ced4da",
                  marginLeft: 20,
                  width: "100%",
                }}
              >
                {fileData?.file?.name ? fileData.file.name : "Upload file"}
              </div>
            </Box>
            <Typography
              style={{
                position: "fixed",
                bottom: 0,
                right: 0,
                cursor: "pointer",
                textTransform: "none",
                color: "#3374b9",
                marginRight: "5px",
                fontSize: "14px",
                padding: 10,
              }}
              onClick={() => {
                setFile((prev) => {
                  let data = [...prev];
                  data.push(fileData?.file);
                  return data;
                });
                setIsUploadDrawerOpen(false);
                setFileData("");

                props.uploadFiles(
                  login.token,
                  brmData?.organizationDetails[0]?.isin,
                  fileData.year,
                  fileData.file,
                  fileData.year.split("-")[0],
                  fileData.year.split("-")[1],
                  fileData.type,
                  brmData?.organizationDetails[0]?._id
                );
              }}
            >
              Upload file
            </Typography>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <div style={{ height: "63vh" }}>
      {isUploadDrawerOpen && fileUploadDrawer()}
      {isDatePickerDialogOpen && yearPickerDialog()}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {showPeerDocs && brmData?.brmSubTabNo === 2 && (
            <Icon>
              <ArrowBackIcon
                onClick={() => setShowpeerDocs(false)}
                style={{ color: "#3374B9", cursor: "pointer" }}
              />
            </Icon>
          )}
          <Typography
            style={{
              fontWeight: 400,
              fontSize: "16px",
              marginLeft: showPeerDocs ? "5px" : 0,
            }}
          >
            {showPeerDocs && brmData?.brmSubTabNo === 2
              ? companyName
              : "Public Reports Upload"}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // width: "20%",
          }}
        >
          <Typography
            style={{
              textTransform: "none",
              color: "#3374b9",
              marginRight: "15px",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            Filter
          </Typography>

          {/* <input
            name="actUpload"
            id="contained-button-activityFile"
            type="file"
            accept=".pdf"
            onChange={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
            style={{ display: "none" }}
            multiple
          />
          <label htmlFor="contained-button-activityFile"> */}
          <Typography
            onClick={() => setIsUploadDrawerOpen(!isUploadDrawerOpen)}
            style={{
              cursor: "pointer",
              textTransform: "none",
              color: "#3374b9",
              marginRight: "5px",
              fontSize: "14px",
            }}
          >
            Upload file
          </Typography>
          {/* </label> */}
        </div>
      </div>
      <MuiThemeProvider theme={appTheme}>
        <AppBar position="static" color="inherit" className={classes.AppBar}>
          <Tabs
            value={reportsTab}
            onChange={(e, newVal) => {
              setReportsTab(newVal);
              // setReportsTab(0);
            }}
          >
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Reports</span>}
            />
            <Tab
              disableRipple
              className={classes.tab}
              label={<span className={classes.tabLabel}>Data Breakdown</span>}
            />
          </Tabs>
        </AppBar>
      </MuiThemeProvider>
      <TabPanel value={reportsTab} index={0}>
        <PublicReports
          {...props}
          publicDocuments={props.riskEvaluator?.publicDocuments}
          file={file}
          handleFileRemove={handleFileRemove}
        />
      </TabPanel>
      <TabPanel value={reportsTab} index={1}>
        {/* <DataBreakdown /> */}
      </TabPanel>
    </div>
  );
}

export default Documents;
