import React from "react";

import { Typography } from "@material-ui/core";

import settingSvg from "../../../images/settingButton.svg";
import thumbUp from "../../../images/thumbUp.svg";
import errorCircle from "../../../images/errorCircle.svg";
import profileOut from "../../../images/profileOut.svg";
import useHoverEffect from "../../../customHooks/getHoveringEffects";
import UserProfileCard from "../userProfileCard/userProfileCard";

export default function EsgMOreBUttons(props) {
  const { hasIconOnly = false, login, logOut } = props;
  const { selectedItem, handleMouseEnter, handleMouseLeave } = useHoverEffect();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const handleMenuUser = (event) => {
    if (event.currentTarget !== anchorElUser) {
      setAnchorElUser(event.currentTarget);
    }
  };
  // console.log(props);
  function handleUserProfileCard() {
    setAnchorElUser(null);
  }

  const moreButtonArr = [
    { src: settingSvg, name: "Settings" },
    { src: thumbUp, name: "Feedback" },
    { src: errorCircle, name: "About & Policy" },
    { src: profileOut, name: "User Name" },
  ];

  return moreButtonArr.map((data, index) => (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: hasIconOnly ? "flex-start" : "center",
        alignItems: "center",
        border: "1px solid #EBF1F8",
        // padding: "6px 20px 6px 10px",
        padding: hasIconOnly ? "5px" : "5px 0px",
        cursor: "pointer",
        backgroundColor: selectedItem === index ? "#EBF1F8" : "#FBFBFB",
      }}
      onClick={(e) => {
        if (index === 3) handleMenuUser(e);
      }}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={() => handleMouseLeave(index)}
    >
      <UserProfileCard
        anchorEl={anchorElUser}
        onClose={handleUserProfileCard}
        userName={login?.name}
        userEmail={login?.email}
        token={login?.token}
        logOut={logOut}
        userProfile={login?.userProfile}
        loginDetails={login}
      />

      <img
        src={data.src}
        style={{ width: "35px", height: "35px" }}
        alt="pdf icon"
      />
      {hasIconOnly && (
        <Typography style={{ fontSize: 13, padding: 5 }}>
          {data.name}
        </Typography>
      )}
    </div>
  ));
}
