import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import leaf from "../../../images/Icon.png";
import network from "../../../images/Icon-1.png";
import piece from "../../../images/Icon-3.png";
import document from "../../../images/Document.png";
import {
  financialInsights,
  getColoredLogo,
  improvementAreas,
  riskArea,
  strongAreas,
} from "../../../utils/riskUtils/riskUtils";

export const OverallRiskCard = ({
  topic,
  heading,
  description,
  gpt = false,
}) => {
  return (
    // <Grid item xs={4}>
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        // border:"solid",
        width: gpt ? "100%" : "30%",
        flexWrap: "wrap",
      }}
    >
      <div>{topic && <img src={getColoredLogo(topic)} alt="logo" />}</div>
      <div style={{ marginTop: "5px" }}>
        {heading && (
          <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
            {heading}
          </Typography>
        )}
        <Typography style={{ fontWeight: 400, fontSize: "13px" }}>
          {description}
        </Typography>
      </div>
    </div>
    // </Grid>
  );
};

function OverallProfile(props) {
  const {
    riskEvaluator,
    getCompanyInsights,
    brmData,
    setProfileTabNo,
    login,
    location,
  } = props;

  useEffect(() => {
    getCompanyInsights(
      login.token,
      brmData?.organizationDetails[0]?.isin || location?.state?.isin
    );
    return () => {
      setProfileTabNo && setProfileTabNo(0);
    };
  }, [brmData?.organizationDetails]);

  const FinancialInsightsCard = ({ heading, description, year }) => {
    return (
      <Grid item xs={12}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <img style={{ width: "80px" }} src={document} alt="logo" />
          </div>
          <div style={{ marginTop: "5px" }}>
            <Typography style={{ fontWeight: 600, fontSize: "14px" }}>
              {`${heading}  ${year}`}
            </Typography>
            <Typography style={{ fontWeight: 400, fontSize: "13px" }}>
              {description}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  };
  const userType = localStorage.getItem("user_type");
  return (
    <>
      {/* {userType === "RE" && false && (
        <div className="scroll" style={{ height: "80vh", paddingBottom: 24 }}>
          <div>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                ESG Risk Area
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                These are the area of risks & identification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {riskArea.map((topic) => (
                  <OverallRiskCard
                    topic={topic.topic}
                    heading={topic.heading}
                    description={topic.description}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Strong Area
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                These are the area of risks & edentification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {strongAreas.map((topic) => (
                  <OverallRiskCard
                    topic={topic.topic}
                    heading={topic.heading}
                    description={topic.description}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Improvement areas
              </Typography>
              <Typography style={{ fontWeight: 400, fontSize: "14px" }}>
                These are the area of risks & edentification of emerging ESG
                risks and potential regulatory changes. With impact percentage
                total of
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {improvementAreas.map((topic) => (
                  <OverallRiskCard
                    topic={topic.topic}
                    heading={topic.heading}
                    description={topic.description}
                  />
                ))}
              </Grid>
            </Grid>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Financial/Repayment Insights
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {financialInsights.map((topic) => (
                  <FinancialInsightsCard
                    heading={topic.heading}
                    description={topic.description}
                    year={
                      props.riskEvaluator?.overallData &&
                      props.riskEvaluator?.overallData.Year
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
      )} */}
      {console.log(props.riskEvaluator)}
      {riskEvaluator?.overallData ? (
        <div
          className="scroll"
          style={{
            height: userType === "RA" ? "80vh" : "56vh",
            paddingBottom: 24,
          }}
        >
          <div>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                ESG Risk Area
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  marginTop: "10px",
                  padding: "0px 8px",
                }}
              >
                {props.riskEvaluator?.overallData &&
                  riskEvaluator?.overallData?.["Risky Areas Summary"]}
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Strong Areas
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  marginTop: "10px",
                  padding: "0px 8px",
                }}
              >
                {props.riskEvaluator?.overallData &&
                  props.riskEvaluator?.overallData?.["Strong Areas Summary"]}
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Weak Areas
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  marginTop: "10px",
                  padding: "0px 8px",
                }}
              >
                {props.riskEvaluator?.overallData &&
                  props.riskEvaluator?.overallData?.["Weak Areas Summary"]}
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Improvement areas
              </Typography>
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  marginTop: "10px",
                  padding: "0px 8px",
                }}
              >
                {" "}
                {props.riskEvaluator?.overallData &&
                  props.riskEvaluator?.overallData?.[
                    "Mitigation Strategies"
                  ]}
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: 15 }}>
            <div>
              <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
                Financial/Repayment Insights
              </Typography>
            </div>

            <Grid
              md={12}
              sm={12}
              style={{ display: "flex", spacing: 2, marginTop: 10 }}
              container
            >
              <Grid container spacing={1}>
                {financialInsights.map((topic) => (
                  <FinancialInsightsCard
                    heading={topic.heading}
                    description={topic.description}
                    year={
                      props.riskEvaluator?.overallData &&
                      props.riskEvaluator?.overallData.Year
                    }
                  />
                ))}
              </Grid>
            </Grid>
          </div>
        </div>
      ) : (
        <Typography align="center">No Data Found</Typography>
      )}
    </>
  );
}

export default OverallProfile;
