import {
  SET_ELASTIC_ORGANIZATIONS,
  SET_COMPANY_DATA,
  SET_COMPANY_ID,
  SET_TOTAL_COMPANIES,
  SET_CONTROVERSY_LINKS,
} from "../../../constants/riskDashboardConstants";
const initial_state = {
  companies: [],
  currentCompanyId: "",
  totalCompanies: "",
  currentCompanyData: {},
  controversyLinks: [],
  totalLinks: 0,
  is_published: false,
};

export default function riskDashboardReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ELASTIC_ORGANIZATIONS:
      return (state = { ...state, companies: action.payload });
    case SET_COMPANY_DATA:
      return (state = { ...state, currentCompanyData: action.payload });
    case SET_TOTAL_COMPANIES:
      return (state = { ...state, totalCompanies: action.payload });
    case SET_COMPANY_ID:
      return (state = { ...state, currentCompanyId: action.payload });
    case SET_CONTROVERSY_LINKS:
      return (state = {
        ...state,
        controversyLinks: action.payload.payload,
        totalLinks: action.payload.total,
        is_published: action.payload.is_published,
      });
    default:
      return state;
  }
}
