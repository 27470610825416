import React, { useEffect, useRef } from "react";

import {
  Avatar,
  Divider,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import DoneIcon from "@material-ui/icons/Done";

import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MoreActionButtons } from "../esgGptCompanyProfile/EsgGptCompanyRight";
import { HeadingCard } from "../esgGptCompanyProfile/EsgGptCompany/EsgGptCompanyInfo";
import arrowUp from "../../../../images/arrow-up-circle.svg";
import avatar from "../../../../images/gptAvatar.svg";

import { useSelector } from "react-redux";
import useChat from "./useChat";
import Spinner from "../../../loader/spinner";

export function UserChatQuestion({ question, companyName }) {
  // const { chatQuestion } = useSelector((state) => state.esgGptReducer);
  return (
    <div style={{ display: "flex", alignItems: "center", padding: "10px" }}>
      <Avatar
        style={{
          backgroundColor: "white",
          width: "35px",
          color: "#3374B9",
          fontSize: "16px",
        }}
      >
        {companyName ? companyName.slice(0, 2) : "Mm"}
      </Avatar>
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: 500,
          color: "#15314E",
          padding: "10px 15px",
        }}
      >
        {question}
      </Typography>
      <IconButton
        style={{ color: "#3374B9", marginLeft: "auto" }}
        aria-label="add to shopping cart"
      >
        <EditOutlinedIcon style={{ color: "#3374B9", cursor: "pointer" }} />
      </IconButton>
    </div>
  );
}

export function UserChatAnswerLayout({
  isLoading,
  hideAvatar = false,
  children,
}) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "5px 5px 10px 10px",
        marginLeft: hideAvatar && "80px",
      }}
    >
      {!hideAvatar && (
        <>
          <Avatar
            style={{
              backgroundColor: "white",
              width: "35px",
              color: "#3374B9",
              fontSize: "16px",
            }}
            src={avatar}
          />

          {/* <img
            src={avatar}
            alt="compantLogo"
            style={{
              alignSelf: "flex-start",
              // marginRight: "10px",
              width: "65px",
              height: "40px",
              marginLeft: "-10px",
            }}
          /> */}
          {/* // <Avatar
        // style={{
        //   alignSelf: isLoading ? "flex-start" : "center",
        //   marginRight: "10px",
        // }}
        // >
        //   IG
        // </Avatar></div> */}
        </>
      )}
      {children}
    </div>
  );
}

export function UserChatAnswerLoading({ handleExpandMore, responseExpand }) {
  return (
    <>
      <CircularProgress
        style={{ color: "#3374B9", padding: "15px" }}
        size={20}
      />
      {/* <Spinner /> */}
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: 600,
          color: "#15314E",
          padding: "10px 10px 10px 10px",
        }}
      >
        Working on the profile
      </Typography>
      {responseExpand ? (
        <IconButton
          style={{ color: "#3374B9" }}
          aria-label="add to shopping cart"
          onClick={handleExpandMore}
        >
          <ExpandLessIcon style={{ fontSize: "25px" }} />
        </IconButton>
      ) : (
        <IconButton
          style={{ color: "#3374B9" }}
          aria-label="add to shopping cart"
          onClick={handleExpandMore}
        >
          <ExpandMoreIcon style={{ fontSize: "25px" }} />
        </IconButton>
      )}
    </>
  );
}

export function DummyAnswer({ answer }) {
  // const { chatAnswer } = useSelector((state) => state.esgGptReducer);
  return (
    <>
      {answer && answer?.split("\n").map((c) => (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#15314E",
            padding: c === "" ? "2px" : "15px",
          }}
        >
          {c}
        </Typography>
      ))}

      <Divider variant="middle" />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <MoreActionButtons />
      </div>
    </>
  );
}

export function UserChatAnswer({ children }) {
  return (
    <div
      style={{ backgroundColor: "#F6F6F6", borderRadius: "8px", width: "100%" }}
    >
      {children}
    </div>
  );
}

// export function FollowUpQuestion({ question, handleFollowUpQuestion }) {
//   return (
//     <div
//       onClick={() => handleFollowUpQuestion(question)}
//       style={{ width: "fit-content", padding: "10px", cursor: "pointer" }}
//     >
//       <HeadingCard primaryHead={question} justifyCenter={true}>
//         <img src={arrowUp} style={{ marginLeft: "5px" }} alt="arrow icon" />
//       </HeadingCard>
//     </div>
//   );
// }

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70vh",
    width: "100%",
    overflow: "scroll",
    marginBottom: "4%",
    // border:"solid",
    backgroundColor: theme.palette.background.paper,
    padding: "1px",
  },
  table: {
    minWidth: 700,
    // marginTop: "10px",
    width: "100%",
    // border:"1px solid"
  },
  tableCell: {
    padding: "15px",
  },
  titleRow: {
    // border:"solid",
    // backgroundColor: "#F6F6F6",
  },
  typography: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    padding: "19px 20px",
    // border:"solid",
    // textAlign:"center"
  },

  typoColorSecondary: {
    color: theme.palette.primary.dark,
  },
  backgroundColorPrimary: {
    backgroundColor: theme.palette.primary.light,
  },

  suggestion: {
    borderRadius: "80px",
    display: "flex",
    marginLeft: 10,
    alignItems: "center",
    width: "fit-content",
    cursor: "pointer",
    marginTop: "10px",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.light}`,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  sourceTypo: {
    backgroundColor: theme.palette.background.contentBG,
    color: theme.palette.primary.main,
    border: "1px solid grey",
    borderRadius: "80px",
    padding: "4px 8px",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: 500,
  },
}));

export function FollowUpQuestion({ question, handleFollowUpQuestion }) {
  const classes = useStyles();
  return (
    <div
      style={{
        borderRadius: "80px",
        display: "flex",
        alignItems: "center",
        width: "fit-content",
        cursor: "pointer",
        marginTop: "10px",
      }}
      className={classes.suggestion}
      // onClick={() => handleFollowUpQuestion(question)}
    >
      <img
        src={arrowUp}
        style={{ marginLeft: "5px", height: "40%" }}
        alt="arrow icon"
      />
      <Typography
        // className={classes.typoColorSecondary}
        style={{
          fontSize: "14px",
          // fontWeight: secondaryHead ? 400 : 700,
          // color: "#242424",
          padding: "2px 6px 2px 3px",
          // lineHeight: "20px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontFamily: "Lato",
        }}
      >
        {question}
      </Typography>
    </div>
  );
}

function EsgGptChatMessageLayout({ companyName }) {
  const { handleChats } = useChat();
  const { chats ,  } = useSelector((state) => state.esgGptReducer);

  const [dummyChat, setChats] = React.useState([0]);
  const [loading, setLoading] = React.useState(false);
  const [responseExpand, setResponseExpand] = React.useState(false);
 


  React.useEffect(
    function () {
      const timeout = setTimeout(function () {
        setChats((preValue) => preValue.map((v) => 1));
        setResponseExpand(false);
      }, 5000);

      return function () {
        clearTimeout(timeout);
      };
    },
    [loading]
  );

  const handleFollowUpQuestion = (question) => {
    setLoading((preValue) => !preValue);
    setChats((preValue) => [...preValue, 0]);
    handleChats(question);
  };

  const handleExpandResponseDetail = () => {
    setResponseExpand((preValue) => !preValue);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  // useEffect(() => {
  //   scrollToBottom();
  // }, [chats.length]);

  const followUpQuestions = [
    `${companyName} sustainability efforts`,
    `${companyName} sustainability practices`,
    `${companyName} sustainability sourcing`,
  ];

  useEffect(() => {
    console.log("scroll");
    chats.length !== 0 && scrollToBottom();
  }, [chats, responseExpand]);

  console.log("chats", chats);
  return (
    <>
      <div style={{ backgroundColor: "#EBF1F8" }}>
        {chats.map((chat) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <UserChatQuestion
              companyName={companyName}
              question={chat?.question}
            />
            <UserChatAnswerLayout isLoading={chat?.isLoading}>
              {chat?.answer ? (
                <UserChatAnswer>
                  <DummyAnswer answer={chat?.answer} />
                </UserChatAnswer>
              ) : (
                <UserChatAnswerLoading
                  handleExpandMore={handleExpandResponseDetail}
                  responseExpand={responseExpand}
                />
              )}
            </UserChatAnswerLayout>

            {responseExpand && chat?.isLoading && (
              <UserChatAnswerLayout
                isLoading={chat?.isLoading}
                hideAvatar={true}
              >
                <UserChatAnswer>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      padding: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <DoneIcon style={{ color: "#3374B9" }} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#15314E",
                          padding: "15px",
                        }}
                      >
                        Checking Companies Profile
                      </Typography>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <DoneIcon style={{ color: "#3374B9" }} />
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#15314E",
                          padding: "15px",
                        }}
                      >
                        Searching documents related to ESG Reporting
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ color: "#3374B9" }}
                        size={20}
                      />
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: "#15314E",
                          padding: "15px",
                        }}
                      >
                        Gathering data on company with ESG context
                      </Typography>
                    </div>
                  </div>
                  <div ref={messagesEndRef} />
                </UserChatAnswer>
              </UserChatAnswerLayout>
            )}
            <div ref={messagesEndRef} />
          </div>
        ))}
      </div>

      {chats.length !== 0 &&
        chats.filter((v, i) => v.isLoading !== false).length === 0 && (
          <div
            style={{
              display: "flex",
              overflowX: "scroll",
              width: "73vw",
              // justifyContent: "space-evenly",
              alignItems: "center",
              // border:"solid",
              gap: "5px",
            }}
          >
            {followUpQuestions.map((question, i) => (
              <div key={i} onClick={() => handleFollowUpQuestion(question)}>
                <FollowUpQuestion question={question} />
              </div>
            ))}
          </div>
        )}
    </>
  );
}

export default EsgGptChatMessageLayout;
