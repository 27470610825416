import React from "react";

import { useDispatch, useSelector } from "react-redux";

import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";

import EsgGptCompanyInfo, {
  HeadingCard,
} from "./EsgGptCompany/EsgGptCompanyInfo";
import { TopicHeader, TopicData } from "../../esgCompanyProfile/esgComponents";
import ScoreChart from "../../esgCompanyProfile/ScoreChart";
import { OverallRiskCard } from "../../esgCompanyProfile/overallProfile";
import {
  improvementAreas,
  riskArea,
  strongAreas,
} from "../../../../utils/riskUtils/riskUtils";
import thumbUp from "../../../../images/thumbUp.svg";
import thumbDown from "../../../../images/thumbDown.svg";
import more from "../../../../images/more.svg";
import share from "../../../../images/share.svg";
import heart from "../../../../images/heart.png";

import PercentageDoughnutChart from "../../../UI/percentageDoughnut.js/percentageDoughnutChart";
import Charts from "./EsgGptCompany/SampleCharts";
import EsgGptChatMessageLayout from "../esgGptChat/EsgGptChatMessageLayout";

import {
  getCompanyInsights,
  getScores,
  getPeerScores,
} from "../../../../redux/actions/riskEvaluator/riskEvaluatorActions";
import { ListItem } from "../EsgGptCompanyList";
import CustomCompaniesRange from "../../../UI/CustomRange/CustomCompaniesRange";
import { EsgAnainsPeersChart } from "../../charts/charts";
import chevronsLeft from "../../../../images/chevronsLeft.png";
import airTable from "../../../../images/airtable.png";
import freetrial from "../../../../images/freetrail.png";
import mcd from "../../../../images/mcd.png";
import useChat from "../esgGptChat/useChat";
import {
  getGptChatHIstory,
  setChatConversations,
} from "../../../../redux/actions/gpt/esgGptAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getControversyLinks } from "../../../../redux/actions/riskdashboard/riskDashboardActions";
import EnergyCharts from "./EsgGptCompany/EnergyCharts";
import CompanyLoading from "../CompanyLoading";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#EBF1F8",
    // color: theme.palette.common.black,
    fontWeight: 500,
    color: "#15314E",
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    color: "#15314E",
  },
}))(TableCell);

function createData(srNo, name, calories, fat, carbs, protein) {
  return { srNo, name, calories, fat, carbs, protein };
}

const rows = [
  createData("Total Electricity Consumption", 51511, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
];

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
    marginTop: "10px",
    width: "100%",
  },
  tableCell: {
    padding: "5px",
  },
  titleRow: {
    backgroundColor: "#F6F6F6",
  },
  typoColorSecondary: {
    color: "#11184F",
  },
  suggestion: {
    backgroundColor: "#f6f6f6",
    border: `1px solid "#EAEEF5"`,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
});

export function BlueBackgroundCard({ heading }) {
  return (
    <div
      style={{
        // height: "50px",
        width: "100%",
        backgroundColor: "#EBF1F8",

        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: "10px",
      }}
    >
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 600,
          color: "#242424",
          padding: "15px",
        }}
      >
        {heading}
      </Typography>
    </div>
  );
}

export function KpiPresentation({
  chartsArr,
  width = "100%",
  chartWidth = "48%",
  kpiChartdata,
}) {
  const classes = useStyles();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {/* {kpiChartdata &&
        Object.entries(kpiChartdata).map((ent) => {
          return (
            <div style={{ width: chartWidth, height: "300px" }}>
              <EnergyCharts kpiChartdata={ent} />

             <Charts /> 
            </div>
          );
        })} */}
      <div style={{ height: "400px", width: "100%" }}>
        {<EnergyCharts kpiChartdata={kpiChartdata} />}
      </div>
      <div style={{ width }}>
        <TableContainer
          style={{
            padding: "3px",
            // width:"70%"
          }}
          component={"div"}
        >
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow style={{}}>
                <StyledTableCell className={classes.tableCell}>
                  Topics
                </StyledTableCell>
                <StyledTableCell className={classes.tableCell}>
                  Current Year
                </StyledTableCell>
                {/* <StyledTableCell className={classes.tableCell}>
                  Previous Year
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {kpiChartdata &&
                Object.entries(kpiChartdata).map(([key, value]) => (
                  <TableRow
                    key={key}
                    className={true ? classes.titleRow : classes.topicRow}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: true ? 10 : 8,
                      }}
                    >
                      {key}
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableCell}>
                      {value}
                    </StyledTableCell>
                    {/* <StyledTableCell className={classes.tableCell}>
                    {row.calories}
                  </StyledTableCell> */}
                  </TableRow>
                ))}
              {/* {rows.map((row) => (
                <TableRow
                  key={row.name}
                  className={row.srNo ? classes.titleRow : classes.topicRow}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: row.srNo ? 10 : 8,
                    }}
                  >
                    {row.srNo}
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableCell}>
                    {row.name}
                  </StyledTableCell>
               <StyledTableCell className={classes.tableCell}>
                    {row.calories}
                  </StyledTableCell> 
                </TableRow>
              ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export function EsgInsightCard({ insight = [] }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
      }}
    >
      <OverallRiskCard
        // topic={topic.topic}
        // heading={topic.heading}
        description={insight}
        gpt={true}
      />
      {/* {insight.map((topic) => (
        <OverallRiskCard
          topic={topic.topic}
          heading={topic.heading}
          description={topic.description}
        />
      ))} */}
    </div>
  );
}

export function EsgInsightMain({ title, area, color, children }) {
  return (
    <div style={{ padding: "15px" }}>
      <HeadingCard primaryHead={title}>
        <IconButton
          variant="outlined"
          style={{
            borderColor: "#FBFBFB",
            backgroundColor: color,
            marginLeft: "10px",
            padding: "5px",

            borderRadius: 5,
          }}
          size="small"
        >
          {children}
        </IconButton>
      </HeadingCard>

      <EsgInsightCard insight={area} />
    </div>
  );
}

export function MoreActionButtons() {
  return (
    <div
      style={{
        // width: "300px",
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        // border:"solid"
      }}
    >
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={thumbUp} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={thumbDown} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={more} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={share} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
    </div>
  );
}

export function ChatPlayGround({ companyName = "" }) {
  const history = useHistory();
  const dispath = useDispatch();
  const { location } = history;
  const { chats } = useSelector((state) => state.esgGptReducer);

  const chatAutoSuggestion = [
    `${companyName} ESG Risk`,
    `Performance & ESG Reporting Of ${companyName}.`,
    `${companyName}. ESG Risk`,
  ];

  const chatSuggestion = [
    `Come up with a concept for ESG`,
    `Recommend a framework`,
    `Compare ESG Principles`,
  ];
  const chatSuggestionCOndition =
    location.pathname === "/esg_gpt/chat" ? chatSuggestion : chatAutoSuggestion;

  const { handleChats } = useChat();
  const [chatInitiate, setChatInitiate] = React.useState(false);
  const handleChatSuggestion = (question) => {
    setChatInitiate(true);
    handleChats(question);
  };
  const classes = useStyles();

  return (
    <>
      {chats.length === 0 && (
        // <div
        //   style={{
        //     display: "flex",
        //     flexWrap: "wrap",
        //     gap: "20px",
        //     justifyContent: "center",
        //   }}
        // >
        //   {chatAutoSuggestion.map((suggestion) => (
        //     <div
        //       style={{ width: "30%", textAlign: "center", cursor: "pointer" }}
        //       onClick={() => handleChatSuggestion(suggestion)}
        //     >
        //       <HeadingCard primaryHead={suggestion} justifyCenter={true} />
        //     </div>
        //   ))}
        // </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {chatSuggestionCOndition.map((suggestion) => (
            <div
              style={{
                width: "32%",
                textAlign: "center",
                cursor: "pointer",
                // border: "solid",
              }}
              onClick={() => handleChatSuggestion(suggestion)}
              className={classes.suggestion}
            >
              <Typography
                className={classes.typoColorSecondary}
                style={{
                  fontSize: "14px",
                  // fontWeight: secondaryHead ? 400 : 700,
                  // color: "#242424",
                  padding: "15px",
                  lineHeight: "20px",
                  fontFamily: "Lato",
                }}
              >
                {suggestion}
              </Typography>
            </div>
          ))}
        </div>
      )}
      {(chatInitiate || chats.length > 0) && (
        <EsgGptChatMessageLayout companyName={companyName} />
      )}
    </>
  );
}

function EsgGptCompanyRight() {
  const login = useSelector((state) => state.login);

  const history = useHistory();

  const { location } = history;

  const is_company_based = location.pathname === "/esg_gpt/chat" ? false : true;

  const riskEvaluator = useSelector((state) => state.riskEvaluator);

  const { newOrganization, selectedChat } = useSelector(
    (state) => state.esgGptReducer
  );
  const { controversyLinks } = useSelector((state) => state?.riskDashboard);
  const { scoreObject, esgScore, esgcScore, energy_kpis, water_kpis } = {
    ...riskEvaluator?.scores,
  };
  const { environment, social, governance } = { ...scoreObject };
  // console.log("newOrganization", newOrganization);
  const { final_esg_score, max_esg_score, min_esg_score, peer_companies } = {
    ...riskEvaluator?.peerScores,
  };
  // const [overallData] = riskEvaluator?.overallData;

  // const { Company, ["Risky Areas Summary"]: riskAreaSummary } = {
  //    ...riskEvaluator?.overallData
  // }
  // console.log(
  //   "riskEvaluator?.peerScores",
  //   riskEvaluator?.peerScores?.peer_companies
  // );
  // const { organizations } = useSelector((state) => state.esgGptReducer);
  const dispatch = useDispatch();
  // console.log("newOrganization", newOrganization);
  React.useEffect(() => {
    if (newOrganization?.isin) {
      dispatch(getScores(login.token, newOrganization?.isin));
      dispatch(getCompanyInsights(login.token, newOrganization?.isin));
      dispatch(
        getPeerScores(
          login?.token,
          newOrganization?.isin,
          newOrganization?.industry_code
        )
      );
      dispatch(
        getGptChatHIstory(
          login?.token,
          newOrganization?._id === undefined ? "" : newOrganization?._id,
          is_company_based,
          selectedChat?._id === undefined ? "" : selectedChat?._id
        )
      );
      dispatch(getCompanyInsights(login.token, newOrganization?.isin));
      dispatch(getControversyLinks(login.token, newOrganization?.isin, 5, 2));
    }

    return () => {
      dispatch(setChatConversations([]));
    };
  }, [newOrganization?.isin]);

  // temporary

  const dataNew = [
    {
      topic: "Environment",
      score: (scoreObject?.environment?.score)?.toFixed(2),
      subTopic: [
        {
          topic: "Energy",
          score: scoreObject?.environment?.data_points?.energy?.toFixed(2),
        },
        {
          topic: "Water",
          score: scoreObject?.environment?.data_points?.water?.toFixed(2),
        },
        {
          topic: "Waste",
          score: scoreObject?.environment?.data_points?.waste?.toFixed(2),
        },
        {
          topic: "Analytic CO2",
          score: scoreObject?.environment?.data_points?.analytic?.toFixed(2),
        },
        {
          topic: "Green revenues",
          score: scoreObject?.environment?.data_points?.green?.toFixed(2),
        },
      ],
    },
    {
      topic: "Social",
      score: (scoreObject?.social?.score)?.toFixed(2),
      subTopic: [
        {
          topic: "Diversity and Inclusion",
          score: scoreObject?.social?.data_points?.diversity?.toFixed(2),
        },
        {
          topic: "Human Rights",
          score: scoreObject?.social?.data_points?.humanRights?.toFixed(2),
        },
        {
          topic: "Career development and training",
          score: scoreObject?.social?.data_points?.career?.toFixed(2),
        },
        {
          topic: "Health and Safety",
          score: scoreObject?.social?.data_points?.health?.toFixed(2),
        },
        {
          topic: "Data privacy",
          score: scoreObject?.social?.data_points?.dataPrivacy?.toFixed(2),
        },
        {
          topic: "Responsible Marketing",
          score: scoreObject?.social?.data_points?.product?.toFixed(2),
        },
      ],
    },
    {
      topic: "Governance",
      score: (scoreObject?.governance?.score)?.toFixed(2),
      subTopic: [
        {
          topic: "Structure",
          score: scoreObject?.governance?.data_points?.structure?.toFixed(2),
        },
        {
          topic: "Compensation",
          score: scoreObject?.governance?.data_points?.compensation?.toFixed(2),
        },
      ],
    },
    // {
    //   topic: "Business",
    //   score: 30,
    //   subTopic: [
    //     { topic: "Product Quality and Safety", score: 10 },
    //     { topic: "Customer Privacy and Data Protection", score: 6 },
    //     { topic: "Supply Chain Management", score: 28 },
    //     { topic: "Marketing and Advertising", score: 25 },
    //     { topic: "Innovation and Intellectual Property", score: 3},
    //   ],
    // },
    // {
    //   topic: "Human Capital",
    //   score: 7,
    //   subTopic: [
    //     { topic: "Work-Life Balance", score: 2 },
    //     { topic: "Employment Training & Development", score: 2 },
    //     { topic: "Diversity and Inclusion", score: 1 },
    //     { topic: "Employee engagement", score: 2 },
    //   ],
    // },
  ];

  const data = [
    {
      type: "The Top 6 Environmental Issues in Business",
      logo: mcd,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
    {
      type: "Responsible Sourcing",
      logo: airTable,
      topic: "Responsible Sourcing",
      link: "https://www.prodigium-pictures.com/blog/environmental-issues-in-business",
    },
    {
      type: "Annual Report",
      logo: chevronsLeft,
      topic: "The Top 6 Environmental Issues in Business",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
    {
      type: "The Top 6 Environmental Issues in Business",
      logo: freetrial,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
  ];

  return (
    <div
      style={{
        height: newOrganization?.loader ? "100%":"88%",
        width: "100%",
        overflow: "scroll",
        marginBottom: "1%",
        padding: "5px",
        // border:"solid",
        // backgroundColor:"red"
      }}
    >
      <div
        style={{
          // height: "25vh",
          width: "100%",
        }}
      >
        <EsgGptCompanyInfo esgScore={esgScore} esgCScore={esgcScore} />
      </div>
      {newOrganization?.loader  ? (
        <CompanyLoading margin={2} />
      ) : (
        <>
          <BlueBackgroundCard heading={"ESG Profile With ESG Score"} />
          <div
            style={{
              // height: "95%",
              width: "100%",
              padding: "15px",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: "5px",
                width: "60%",
                // border:"solid"
              }}
            >
              {[...dataNew, 1].map((data, index, arr) => {
                const lastElement = index === arr.length - 1;
                return (
                  <>
                    {lastElement ? (
                      <div style={{ width: "48%" }}>
                        <BlueBackgroundCard heading={"ESG Score"} />
                        <Typography
                          style={{
                            fontSize: "20px",
                            fontWeight: 600,
                            color: "#242424",
                            padding: "10px",
                          }}
                        >
                          {esgScore?.toFixed(2)}%
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ width: "48%" }}>
                        <TopicHeader topic={data.topic} score={data.score} />
                        {data.subTopic.map((sub) => (
                          <TopicData topic={sub.topic} score={sub.score} />
                        ))}
                      </div>
                    )}
                  </>
                );
              })}
              {/* <div style={{ width: "48%" }}>
              <BlueBackgroundCard heading={"ESG Score"} />
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#242424",
                  padding: "5px",
                }}
              >
                {esgScore?.toFixed(2)}%
              </Typography>
            </div> */}
            </div>
            <div
              style={{
                display: "flex",
                padding: "50px 20px 50px 10px",
                // border:"solid",
                width: "35%",
              }}
            >
              {riskEvaluator?.scores && (
                <ScoreChart scores={{ ...riskEvaluator?.scores }} />
              )}
            </div>
          </div>
          <BlueBackgroundCard heading={" ESG Controversy Score (ESGC)"} />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "48%",
                padding: "15px",
              }}
            >
              <HeadingCard primaryHead={"Online Footprint Score"} />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ padding: 10 }}>
                  <img src={heart} alt="heart" />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "20%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#ECA270",
                        borderRadius: "100%",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                    <Typography style={{ fontSize: 13, padding: 10 }}>
                      Online Foot Print Score.
                    </Typography>
                  </div>
                  <Typography
                    style={{ fontSize: 13, fontWeight: 600, padding: 10 }}
                  >
                    {`${
                      Math.round(riskEvaluator?.scores?.esgcScore) || 0
                    } / 100`}
                  </Typography>
                </div>

                <div>
                  <PercentageDoughnutChart
                    color="#ECA270"
                    emptyColor="#F9EEED"
                    footPrint={true}
                    percentage={
                      Math.round(riskEvaluator?.scores?.esgcScore) || 0
                    }
                  />
                </div>
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Typography style={{ fontSize: 13 }}>
                    These search results provide a glimpse into the various
                    environmental issues faced by companies and the importance
                    of addressing them. It's crucial for businesses to consider
                    their environmental impact and take steps towards
                    sustainability.
                  </Typography>
                  <Typography style={{ fontSize: 13, marginTop: 8 }}>
                    Based on our market insights and benchmarking tool, is in
                    the second quartile of the Peer group range and has
                    significant scope to improve.
                  </Typography>
                  <Typography style={{ fontSize: 13, marginTop: 8 }}>
                    Our curated sustainability solutions are designed to help
                    you progress your company’s sustainability goals to the next
                    level.
                  </Typography>
                </div>
                <div></div>
              </div>
            </div>
            <div
              style={{
                width: "48%",
                padding: "15px",
              }}
            >
              <HeadingCard primaryHead={"Reference Link"} />

              <List
                style={{ height: "325px", overflow: "scroll", width: "430px" }}
              >
                {controversyLinks &&
                  controversyLinks.map((v) => {
                    return (
                      <ListItem style={{ padding: 5 }}>
                        <ListItemAvatar>
                          <img
                            src={v?.socialimage}
                            style={{ padding: "0px 5px" }}
                            width={"70px"}
                            height={"100%"}
                            alt="star"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: "#000000",
                              }}
                            >
                              {v?.title}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 11,
                                color: "#6F7170",
                                width: "80%",
                                overflow: "hidden",
                              }}
                            >
                              {v?.url}
                            </Typography>
                          }
                        />
                        <ListItemAvatar style={{ padding: 5 }} align="center">
                          <IconButton
                            style={{ padding: 5 }}
                            edge="end"
                            aria-label="delete"
                          >
                            <LaunchIcon
                              onClick={() => window.open(v.url)}
                              style={{ color: "#3374B9" }}
                            />
                          </IconButton>
                        </ListItemAvatar>
                      </ListItem>
                    );
                  })}
              </List>
            </div>
          </div>
          <BlueBackgroundCard
            heading={"ESG KPI Data Breakdown With Data Representation"}
          />
          <div style={{ padding: "15px" }}>
            <HeadingCard primaryHead={"Energy"} />

            <KpiPresentation chartsArr={[1, 2]} kpiChartdata={energy_kpis} />
          </div>

          <div style={{ padding: "15px" }}>
            <HeadingCard primaryHead={"Water"} />
            <KpiPresentation
              kpiChartdata={water_kpis}
              chartsArr={[1]}
              width={"65%"}
              chartWidth={"33%"}
            />
          </div>

          <BlueBackgroundCard heading={"ESG Insights"} />
          <EsgInsightMain
            title={"ESG Risk Area"}
            area={
              riskEvaluator?.overallData &&
              riskEvaluator?.overallData?.["Risky Areas Summary"]
            }
            color={"crimson"}
          >
            <ReportProblemOutlinedIcon
              style={{ color: "#FBFBFB" }}
              fontSize="small"
            />
          </EsgInsightMain>
          <EsgInsightMain
            title={"Strong Area"}
            area={
              riskEvaluator?.overallData &&
              riskEvaluator?.overallData?.["Strong Areas Summary"]
            }
            color={"lightgreen"}
          >
            <CheckOutlinedIcon style={{ color: "#FBFBFB" }} fontSize="small" />
          </EsgInsightMain>
          <EsgInsightMain
            title={"Improvement Area"}
            area={
              riskEvaluator?.overallData &&
              riskEvaluator?.overallData?.["Weak Areas Summary"]
            }
            color={"#4169E1"}
          >
            <KeyboardArrowUpOutlinedIcon
              style={{ color: "#FBFBFB" }}
              fontSize="small"
            />
          </EsgInsightMain>
          <BlueBackgroundCard heading={"Peer Analysis"} />
          {/* <div>
          <CustomCompaniesRange
            companyScores={[30, 40, 45, 50, 60]}
            score={final_esg_score?.score.toFixed(2)}
            lowerRange={min_esg_score?.score?.toFixed(2)}
            upperRange={max_esg_score?.score?.toFixed(2)}
          />
        </div> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: "45%" }}>
              <CustomCompaniesRange
                fullwidth={false}
                peer_companies={peer_companies}
                score={final_esg_score?.score?.toFixed(2)}
                lowerRange={min_esg_score?.score?.toFixed(2)}
                upperRange={max_esg_score?.score?.toFixed(2)}
              />
              {/* <CustomRange
              score={final_esg_score?.score.toFixed(2)}
              lowerRange={min_esg_score?.score?.toFixed(2)}
              upperRange={max_esg_score?.score?.toFixed(2)}
            /> */}
            </div>
            <div style={{ width: "45%" }}>
              <Typography
                style={{
                  paddingLeft: 15,
                  fontWeight: 500,
                  marginTop: "2px",
                  fontSize: "14px",
                  color: "#333F48",
                }}
              >
                Based on our market insights and benchmarking tool,
              </Typography>
              <Typography
                style={{
                  paddingLeft: 15,
                  color: "#333F48",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginTop: "4px",
                }}
              >
                {` Your company is in the
                    ${
                      final_esg_score?.score <= 25
                        ? "first Quartile"
                        : final_esg_score?.score > 25 &&
                          final_esg_score?.score <= 50
                        ? "second quartile"
                        : final_esg_score?.score > 50 &&
                          final_esg_score?.score < 75
                        ? "third Quartile"
                        : "fourth quartile"
                    }
                    of the Peer group range and has significant scope to improve.`}
              </Typography>
              <Typography
                style={{
                  paddingLeft: 15,
                  fontWeight: 500,
                  marginTop: "2px",
                  fontSize: "14px",
                  color: "#333F48",
                }}
              >
                Our curated sustainability solutions are designed to help you
                progress your company’s sustainability goals to the next level.
              </Typography>

              {[
                `“Good sustainability practices positively influenced the stock price performance of 80% of companies and lowered the cost of capital of 90% of companies. Solid ESG practices are found to have resulted in better operational performance in 88% of companies”`,
              ].map((topic) => {
                return (
                  <Typography
                    key={topic}
                    style={{
                      paddingLeft: 15,
                      fontSize: "13px",
                      color: "#333F48",
                      marginTop: "15px",
                    }}
                  >
                    {topic}
                  </Typography>
                );
              })}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "350px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: "90%", height: "300px" }}>
              {/* <EsgAnainsPeersChart /> */}
              <Charts isPeer={true} peer_companies={peer_companies} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#242424",
                padding: "15px",
              }}
            >
              Would you like to share these data
            </Typography>
            <MoreActionButtons />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#242424",
                padding: "15px",
              }}
            >
              {`Ask a question about ${newOrganization?.name}...`}
            </Typography>
          </div>
          <div style={{ padding: "15px" }}>
            <ChatPlayGround companyName={newOrganization?.name} />
          </div>
        </>
      )}{" "}
    </div>
  );
}

export default EsgGptCompanyRight;
