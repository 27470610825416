import React from "react";

import EsgGptlayout from "../EsgGptlayout";
import EsgGptCompanyRight from "./EsgGptCompanyRight";
import EsgGptChatField from "../EsgGptChatField";
import EsgGptCompanyList from "../EsgGptCompanyList";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import EsgGptUpdatedList from "../esgGptAddNewCompany/EsgGptUpdatedList";

function EsgGptCompanyProfiles(props) {
  const { controversyLinks } = useSelector((state) => state?.riskDashboard);
  const { organizations,    newOrganization  } = useSelector(
    (state) => state.esgGptReducer
  );
  
  return (
    <EsgGptlayout
      EsgCompanyList={organizations?.favorites?.length>0 ? <EsgGptUpdatedList /> : <EsgGptCompanyList />}
      login={props.login}
      controversyLinks={controversyLinks}
      logOut={props.logOut}
    >
      <EsgGptCompanyRight />
      {!newOrganization?.loader &&  <EsgGptChatField />}
    </EsgGptlayout>
  );
}

export default EsgGptCompanyProfiles;
