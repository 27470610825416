import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import EsgGptMainLeft from "../../screen/esgGpt/EsgMainLeft";
import EsgGptDrawerTabs from "./EsgGptDrawerTabs";
import EsgMoreBUttons from "./EsgGptMoreBUtton";
import EsgGptSearchBar from "./EsgGptSearchBar";
import EsgGptAddCompanyButton from "./EsgGptAddCompanyButton";
import EsgGptCompanyList from "./EsgGptCompanyList";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CssBaseline,
  Drawer,
  Icon,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import chevronsLeft from "../../../images/chevronsLeft.png";
import externalLink from "../../../images/externalLink.png";
import airTable from "../../../images/airtable.png";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LaunchIcon from "@material-ui/icons/Launch";
const useStylesLayput = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "88vh",
    overflow: "hidden",
    // border:"solid",

    // flexDirection:"column"
  },

  // necessary for content to be below app bar
  content: {
    flexGrow: 1,
    // overflow:"hidden",
    paddingRight: theme.spacing(1),
    // border:"solid",
    // marginRight: "10px",
  },
}));

function EsgGptlayout({ EsgCompanyList,controversyLinks, children, ...props }) {
  const classes = useStylesLayput();
  const [isgptDrawerOpen, setIsgptDrawerOpen] = useState(false);

  const data = [
    {
      type: "Annual Report",
      logo: airTable,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
    {
      type: "Annual Report",
      logo: chevronsLeft,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
    {
      type: "Annual Report",
      logo: chevronsLeft,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
  ];
  const gptDrawer = () => {
    return (
      <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="right"
        onClose={() => setIsgptDrawerOpen(false)}
        // variant="persistent"
        width="lg"
        open={isgptDrawerOpen}
      >
        <div style={{ width: "500px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <Typography
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              Companies referance link
            </Typography>
            <Typography
              onClick={() => setIsgptDrawerOpen(!isgptDrawerOpen)}
              style={{ padding: "5px 0px", fontWeight: 500, fontSize: 16 }}
            >
              <CloseIcon />
            </Typography>
          </div>

          <div>
            {/* <Typography
              style={{
                float: "right",
                cursor: "pointer",
                textTransform: "none",
                color: "#3374b9",
                marginRight: "5px",
                fontSize: "14px",
              }}
            >
            </Typography> */}
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                style={{
                  backgroundColor: "#EBF1F8",
                  fontWeight: 600,
                  fontSize: 16,
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                Annual Reference Links
              </AccordionSummary>
              {console.log(props)}
              {controversyLinks &&
              controversyLinks.map((v) => {
                return (
                  <ListItem style={{ padding: 5 }}>
                    <ListItemAvatar>
                      <img
                        src={v?.socialimage}
                        style={{ padding: "0px 5px" }}
                        width={"70px"}
                        height={"100%"}
                        alt="star"
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: 13,
                            fontWeight: 500,
                            color: "#000000",
                          }}
                        >
                          {v?.title}
                        </Typography>
                      }
                      secondary={
                        <Typography
                          variant="body2"
                          style={{
                            fontSize: 12,
                            color: "#6F7170",
                            width: "100%",
                          }}
                        >
                          {v?.url}
                        </Typography>
                      }
                    />
                    <ListItemAvatar style={{ padding: 5 }} align="center">
                      <IconButton
                        style={{ padding: 5 }}
                        edge="end"
                        aria-label="delete"
                      >
                        <LaunchIcon
                          onClick={() => window.open(v.url)}
                          style={{ color: "#3374B9" }}
                        />
                      </IconButton>
                    </ListItemAvatar>
                  </ListItem>
                );
              })}
              {/* {data.map((report) => (
                <AccordionDetails>
                  <div style={{alignItems:"center"}}>
                    <img
                      style={{ objectFit: "contain",width:'90px',height:'90px' }}
                      src={report.logo}
                      alt="logo"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      padding: "5px 0px 5px 4px",
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {report.topic}
                    </Typography>
                    <Typography style={{ color: "#6F7170", fontSize: 14 }}>
                      {report.link}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      onClick={() => {
                        window.open(report.link);
                      }}
                    >
                      <img
                        style={{ objectFit: "contain", width: "20px" }}
                        src={externalLink}
                        alt="openLink"
                      />
                    </Icon>
                  </div>
                </AccordionDetails>
              ))} */}
            </Accordion>
            {/* <Accordion defaultExpanded={true}>
              <AccordionSummary
                style={{
                  backgroundColor: "#EBF1F8",
                  fontWeight: 600,
                  fontSize: 16,
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                Sustainability reports
              </AccordionSummary>

              {data.map((report) => (
                <AccordionDetails>
                  <div style={{ alignItems: "center" }}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "90px",
                        height: "90px",
                      }}
                      src={report.logo}
                      alt="logo"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      padding: "5px 0px 5px 4px",
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {report.topic}
                    </Typography>
                    <Typography style={{ color: "#6F7170", fontSize: 14 }}>
                      {report.link}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      onClick={() => {
                        window.open(report.link);
                      }}
                    >
                      <img
                        style={{ objectFit: "contain", width: "20px" }}
                        src={externalLink}
                        alt="openLink"
                      />
                    </Icon>
                  </div>
                </AccordionDetails>
              ))}
            </Accordion> */}
            {/* <Accordion defaultExpanded={true}>
              <AccordionSummary
                style={{
                  backgroundColor: "#EBF1F8",
                  fontWeight: 600,
                  fontSize: 16,
                }}
                expandIcon={<ExpandMoreIcon />}
              >
                ESG Reports
              </AccordionSummary>

              {data.map((report) => (
                <AccordionDetails>
                  <div style={{ alignItems: "center" }}>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "90px",
                        height: "90px",
                      }}
                      src={report.logo}
                      alt="logo"
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      padding: "5px 0px 5px 4px",
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>
                      {report.topic}
                    </Typography>
                    <Typography style={{ color: "#6F7170", fontSize: 14 }}>
                      {report.link}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Icon
                      onClick={() => {
                        window.open(report.link);
                      }}
                    >
                      <img
                        style={{ objectFit: "contain", width: "20px" }}
                        src={externalLink}
                        alt="openLink"
                      />
                    </Icon>
                  </div>
                </AccordionDetails>
              ))}
            </Accordion> */}
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <div className={classes.root}>
      {isgptDrawerOpen && gptDrawer()}
      <CssBaseline />
      <EsgGptMainLeft
        drawerTabs={<EsgGptDrawerTabs />}
        drawerCompanyList={EsgCompanyList}
        drawerSearchBar={<EsgGptSearchBar EsgCompanyList={EsgCompanyList}/>}
        drawerAddCompanyButton={<EsgGptAddCompanyButton />}
        drawerMoreButton={<EsgMoreBUttons hasIconOnly={true} {...props} />}
        width={280}
      />

      <main className={classes.content}>{children}</main>
      <img
        onClick={() => setIsgptDrawerOpen(!isgptDrawerOpen)}
        style={{
          width: "22px",
          height: "20px",
          paddingRight: 5,
          cursor: "pointer",
        }}
        src={chevronsLeft}
        alt="arrow"
      />
      {/* <Typography style={{paddingRight:15}}>{"<<"}</Typography> */}
    </div>
  );
}

export default EsgGptlayout;
