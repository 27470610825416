import React from "react";

import { useDispatch, useSelector } from "react-redux";

import star from "../../../../../images/mcDonald.svg";
import EsgGptNewCompanyDetails from "../../esgGptAddNewCompany/EsgGptNewCompanyDetails";
import { Typography } from "@material-ui/core";
import useHoverEffect from "../../../../../customHooks/getHoveringEffects";

export function HeadingCard({
  primaryHead,
  secondaryHead,
  justifyCenter = false,
  isInsight = true,
  children,
}) {
  const { isHovering, handleMouseEnter, handleMouseLeave } = useHoverEffect();

  return (
    <div
      style={{
        border: "1px solid #F6F6F6",
        width: "100%",
        height: "50px",
        backgroundColor: isHovering ? "#EBF1F8" : "#FBFBFB",
        borderRadius: "8px",
        display: "flex",
        justifyContent: justifyCenter
          ? "center"
          : isInsight
          ? "flex-start"
          : "space-between",
        alignItems: "center",
      }}
      onMouseEnter={() => handleMouseEnter(justifyCenter)}
      onMouseLeave={() => handleMouseLeave(justifyCenter)}
    >
      {children}
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: secondaryHead ? 400 : 600,
          color: "#242424",
          padding: "12px",
          lineHeight: "20px",
        }}
      >
        {primaryHead}
      </Typography>
      <Typography
        style={{
          fontSize: "13px",
          fontWeight: 600,
          color: "#242424",
          padding: "12px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {secondaryHead}
        {secondaryHead && (
          <span
            style={{
              fontSize: "13px",
              fontWeight: 400,
              color: "#242424",
              paddingLeft: "2px",
            }}
          >
            {` / ${100}`}
          </span>
        )}
      </Typography>
    </div>
  );
}

function EsgGptCompanyInfo({ esgScore, esgCScore }) {
  const { newOrganization } = useSelector((state) => state.esgGptReducer);
  console.log('newOrganization',newOrganization)
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        // backgroundColor:"red"
      }}
    >
      <img
        src={newOrganization?.logo?.url}
        width={"15%"}
        height={"80%"}
        alt="star"
        // style={{border:"1px solid rgb(245,245,245,0)"}}
      />
      <div style={{ width: "45%", height: "90%" }}>
        <EsgGptNewCompanyDetails />
      </div>
      <div
        style={{
          width: "30%",
          height: "90%",
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          gap: "10px",
          // border:"solid"
        }}
      >
        <HeadingCard
          primaryHead={"ESG Score"}
          secondaryHead={`${esgScore?.toFixed(2) || 0}`}
          isInsight={false}
        />
        <HeadingCard
          primaryHead={"ESG Controversy Score"}
          secondaryHead={`${esgCScore?.toFixed(2) || 0}`}
          isInsight={false}
        />
        <HeadingCard
          primaryHead={"ESG maturity"}
          secondaryHead={"48"}
          isInsight={false}
        />
      </div>
    </div>
  );
}

export default EsgGptCompanyInfo;
