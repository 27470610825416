import React from "react";

import { InputAdornment } from "@material-ui/core";
import Textfield from "../../UI/textfield/textfield";
import SearchIcon from "@material-ui/icons/Search";
import { useDispatch } from "react-redux";
import { setSearcheText } from "../../../redux/actions/gpt/esgGptAction";

export default function EsgGptSearchBar({ EsgCompanyList }) {
  const dispatch = useDispatch();
  const handleSearch = (value) => {
    dispatch(setSearcheText(value))
    // console.log(value);
  };

  return (
    <div
      style={{
        width: "96%",
        marginLeft: "6px",
        marginTop: "10px",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
      }}
    >
      <Textfield
        name="Search"
        variant="outlined"
        fullWidth
        size="small"
        type="text"
        // style={{border: "1px solid #E9E9E9",fontSize:13}}
        label="Search by Ticket or Name"
        onChange={(e) => {
          handleSearch(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon style={{ color: "#3374B9" }} />
            </InputAdornment>
          ),
        }}
        // value={searchCompanyText}
      />
    </div>
  );
}
