import { CardContent, Paper, Typography } from "@material-ui/core";
import React from "react";
import RadarChart from "../../../UI/radarChart/radarChart";

function DimentionCharts(props) {
  const { scoreObject } = props?.riskEvaluator?.scores;
  const { environment, social, governance } = scoreObject;
  console.log(props);

  return (
    <div style={{ height: "63vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <Typography style={{ fontWeight: 600, fontSize: "16px" }}>
            {props?.brmData?.organizationDetails[0]?.name || "Dimention Charts"}
          </Typography>
        </div>
      </div>
      {environment ? (
        <div className="scroll" style={{ height: "61vh" }}>
          <Paper>
            <RadarChart
              lab="OverAll"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={["Environment", "Social", "Governance"]}
              chartEsgData={[
                environment?.score * 100 || 0,
                social?.score * 100 || 0,
                governance?.score * 100 || 0,
              ]}
              {...props}
            />
          </Paper>
          <Paper>
            <RadarChart
              lab="Environment"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={[
                "Energy",
                "Water",
                "Waste",
                "Analytic CO2",
                "Green revenues",
              ]}
              chartEsgData={Object.values(environment?.data_points) || []}
              {...props}
            />
          </Paper>
          <Paper>
            <RadarChart
              lab="Social"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={[
                "Diversity and Inclusion",
                "Human Rights",
                "Career development and training",
                "Health and Safety",
                "Data privacy",
                "Responsible Marketing",
              ]}
              chartEsgData={Object.values(social?.data_points) || []}
              {...props}
            />
          </Paper>
          <Paper>
            <RadarChart
              lab="Governance"
              labels={["Structure", "Compensation", ""]}
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              chartEsgData={Object.values(governance?.data_points) || []}
              {...props}
            />
          </Paper>
        </div>
      ) : (
        <div className="scroll" style={{ height: "61vh" }}>
          <Paper>
            <RadarChart
              lab="OverAll"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={["Environment", "Social", "Governance"]}
              chartEsgData={[0, 0, 0]}
              {...props}
            />
          </Paper>
          <Paper>
            <RadarChart
              lab="Environment"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={[
                "Energy",
                "Water",
                "Waste",
                "Analytic CO2",
                "Green revenues",
              ]}
              chartEsgData={[]}
              {...props}
            />
          </Paper>
          <Paper>
            <RadarChart
              lab="Social"
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              labels={[
                "Diversity and Inclusion",
                "Human Rights",
                "Career development and training",
                "Health and Safety",
                "Data privacy",
                "Responsible Marketing",
              ]}
              chartEsgData={[]}
              {...props}
            />
          </Paper>
          <Paper>
            <RadarChart
              lab="Governance"
              labels={["Structure", "Compensation", ""]}
              backgroundColor="#3374b933"
              borderColor="#3374b9"
              chartEsgData={[]}
              {...props}
            />
          </Paper>
        </div>
      )}
    </div>
  );
}

export default DimentionCharts;
