import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { Button, TextField } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import CachedIcon from "@material-ui/icons/Cached";
import { makeStyles } from "@material-ui/core/styles";
import useChat from "./esgGptChat/useChat";
import sendButton from "../../../images/sendButton.png";
import { set_snack_bar } from "../../../redux/actions/snackbar/snackbar_action";
import { useDispatch } from "react-redux";
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C0D4E9",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C0D4E9",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C0D4E9",
    },
    "& .MuiOutlinedInput-input": {
      color: "#3374B9",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#3374B9",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#3374B9",
    },
    "& .MuiInputLabel-outlined": {
      color: "#3374B9",
    },
    "&:hover .MuiInputLabel-outlined": {
      color: "#3374B9",
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#3374B9",
    },
    color: "#3374B9",
    backgroundColor: "#F9F9F9",
    borderRadius: "8px",
  },
});

function EsgGptChatField() {
  const { handleChats } = useChat();

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch()
  const { location } = history;

  const showAddButton = location.pathname === "/esg_gpt/chat";

  const [question, setQuestion] = useState("");

  const handleQuestion = (e) => {
    setQuestion(e.target.value);
  };

  const handleConversations = () => {
    if (question) {
      handleChats(question);
      setQuestion("");
    }else{
      dispatch(set_snack_bar(true,"Please Enter your question or Select a Suggested Question"))
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // border: "solid",
        height: "50px",
      }}
    >
      {showAddButton && (
        <IconButton
          style={{ color: "#3374B9", marginRight: "10px" }}
          aria-label="add to shopping cart"
        >
          <Icon style={{ color: "#3374B9" }}>add</Icon>
        </IconButton>
      )}
      <TextField
        className={classes.root}
        id="outlined-basic"
        label="Ask Question be specific and use company names instead of tickets"
        variant="outlined"
        style={{
          width: "70%",
          margin: "2px 5px",
        }}
        value={question}
        onChange={handleQuestion}
      />
      {/* <Button
        variant="contained"
        // color="primary"
        style={{ backgroundColor: "#3374B9", borderRadius: "8px" }}
        // className={classes.button}
        endIcon={<Icon style={{ color: "#EBF1F8" }}>send</Icon>}
      /> */}
      <img
        onClick={handleConversations}
        src={sendButton}
        alt="send"
        style={{ width: "60px", marginLeft: "10px", cursor: "pointer" }}
      />
      {/* <IconButton
        variant="outlined"
        style={{
          borderColor: "#FBFBFB",
          backgroundColor: "#3374B9",
          marginLeft: "10px",
          // padding: "5px",
          height: "90%",
          borderRadius: 5,
          width: "50px",
        }}
        size="small"
        disabled={question === ""}
        onClick={handleConversations}
      >
      
      <Icon style={{ color: "#EBF1F8" }}>send</Icon> 
      </IconButton> */}
      <IconButton
        style={{ color: "#3374B9", marginLeft: "10px" }}
        aria-label="add to shopping cart"
      >
        <CachedIcon style={{ fontSize: "30px" }} />
      </IconButton>
    </div>
  );
}

export default EsgGptChatField;
