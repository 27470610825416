import React from "react";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "@material-ui/core";
import {
  setAcknowledged,
  setNewCompanyDetails,
  setNewCompanyLogo,
  setSelectedChat,
} from "../../../redux/actions/gpt/esgGptAction";

export default function EsgGptAddCompanyButton() {
  const history = useHistory();
  const dispath = useDispatch();
  const { location } = history;

  const buttonText =
    location.pathname === "/esg_gpt/chat" ? "Add New Chat" : "Add New Company";

  const handleChangeRoute = () => {
    let path = "/esg_gpt/add_company";
    location.pathname !== "/esg_gpt/chat" && history.push(path);

    dispath(setAcknowledged(false));
    dispath(setNewCompanyDetails({}));
    dispath(setNewCompanyLogo(""));
    dispath(setSelectedChat({}));
  };
  return (
    <Button
      style={{ color: "#3374B9", marginTop: "5px", textTransform:'capitalize' ,backgroundColor:'#FEFEFE'}}
      onClick={handleChangeRoute}
    >
      {buttonText}
    </Button>
  );
}
