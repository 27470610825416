import {
  SET_ACKNOWLEDGED,
  SET_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_NEW_ORGANIZATION_LOGO,
  SET_CHAT_QUESTION,
  SET_CHAT_ANSWER,
  SET_CHATS_CONVERSATION,
  SET_CHAT_GROUP,
  SET_SELECTED_CHAT,
  SET_SEARCHED_ORGANIZATION,
  SET_SEARCHED_TEXT,
} from "../../../constants/esgGptConstant";

const initial_state = {
  acknowledged: false,
  organizations: [],
  searchedOrganizations: { filtered: "", org: [] },
  newOrganization: {},
  newLogo: "",
  chatQuestion: "",
  chatAnswer: "",
  chats: [],
  chatsList: [],
  selectedChat: {},
};

export default function esgGptReducer(state = initial_state, action) {
  switch (action.type) {
    case SET_ACKNOWLEDGED:
      return (state = { ...state, acknowledged: action.payload });
    case SET_ORGANIZATION:
      return (state = { ...state, organizations: action.payload });
    case SET_SEARCHED_ORGANIZATION:
      return (state = {
        ...state,
        searchedOrganizations: {
          text: state.searchedOrganizations.text,
          filtered: action.payload,
        },
      });
    case SET_SEARCHED_TEXT:
      return (state = {
        ...state,
        searchedOrganizations: {
          ...state.searchedOrganizations,
          text: action.payload,
        },
      });
    case SET_NEW_ORGANIZATION:
      return (state = { ...state, newOrganization: action.payload });
    case SET_NEW_ORGANIZATION_LOGO:
      return (state = { ...state, newLogo: action.payload });
    case SET_CHAT_QUESTION:
      return (state = { ...state, chatQuestion: action.payload });
    case SET_CHAT_ANSWER:
      return (state = { ...state, chatAnswer: action.payload });
    case SET_CHATS_CONVERSATION:
      return (state = { ...state, chats: action.payload });
    case SET_CHAT_GROUP:
      return (state = {
        ...state,
        chatsList: action.payload,
        selectedChat: action.payload.length > 0 ? action.payload[0] : {},
      });
    case SET_SELECTED_CHAT:
      return (state = {
        ...state,
        selectedChat: action.payload,
      });
    default:
      return state;
  }
}
